import React from 'react'
import {
  Box,
  Button,
  CardActions,
  CardContent,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { Controller } from 'react-hook-form'
import LaboratorySelector from 'components/LaboratorySelector'
import FlexContainer from 'components/FlexContainer'
import PharmaceuticalFormSelector from 'components/PharmaceuticalFormSelector'
import LocationSelector from 'components/LocationSelector'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

const inputSpace = 0.5
const blockSpace = 2
const groupSpace = 3
const inputVariant = 'outlined'
const inputSize = 'medium'

export default function Form({
  hasBarcode,
  toggleHasBarcode,
  prescriptionRequired,
  togglePrescriptionRequired,
  getFormProps,
  errors,
  handleSubmit,
  onNextStep,
  watch,
  setValue,
  codeAlreadyExists,
}) {
  const hasError = key => {
    return Boolean(errors[key])
  }

  return (
    <form onSubmit={handleSubmit(onNextStep)}>
      <CardContent
        sx={{
          padding: '8px 24px',
        }}
      >
        <Box
          sx={{
            mb: groupSpace,
          }}
        >
          <FormControlLabel
            control={
              <Switch checked={hasBarcode} onChange={toggleHasBarcode} />
            }
            label="Código de barras"
          />
        </Box>

        <Box sx={{ mt: inputSpace, mb: groupSpace }}>
          {hasBarcode ? (
            <Controller
              {...getFormProps({
                name: 'product_code',
                rules: { required: hasBarcode },
              })}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  size={inputSize}
                  variant={inputVariant}
                  label="Escanear código de barras"
                  error={hasError('product_code')}
                  helperText={hasError('product_code') ? 'Campo requerido' : ''}
                  onBlur={codeAlreadyExists}
                />
              )}
            />
          ) : (
            <Controller
              {...getFormProps({
                name: 'generated_product_code',
                rules: { required: !hasBarcode },
              })}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  readOnly
                  size={inputSize}
                  variant={inputVariant}
                  label="Código del producto"
                  error={hasError('generated_product_code')}
                  helperText={
                    hasError('generated_product_code') ? 'Campo requerido' : ''
                  }
                />
              )}
            />
          )}
        </Box>

        <Typography variant="subtitle1" sx={{ mb: inputSpace }}>
          Detalle del producto
        </Typography>
        <Box sx={{ mb: blockSpace }}>
          <Controller
            {...getFormProps({ name: 'product_name' })}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                size={inputSize}
                variant={inputVariant}
                label="Nombre del producto"
                error={hasError('product_name')}
                helperText={hasError('product_name') ? 'Campo requerido' : ''}
              />
            )}
          />
        </Box>
        <Box sx={{ mb: blockSpace }}>
          <PharmaceuticalFormSelector
            size={inputSize}
            variant={inputVariant}
            value={watch('pharmaceutical_form')}
            setValue={value => {
              setValue('pharmaceutical_form', value)
            }}
          />
        </Box>
        <Box sx={{ mb: blockSpace }}>
          <LaboratorySelector
            size={inputSize}
            variant={inputVariant}
            value={watch('laboratory')}
            setValue={value => {
              setValue('laboratory', value)
            }}
          />
        </Box>

        <Box>
          <LocationSelector
            size={inputSize}
            variant={inputVariant}
            value={watch('location')}
            setValue={value => {
              setValue('location', value)
            }}
          />
        </Box>

        <Typography variant="subtitle1" sx={{ mt: groupSpace, mb: inputSpace }}>
          Stock
        </Typography>

        <FlexContainer mb={blockSpace}>
          <TextField
            fullWidth
            variant={inputVariant}
            size={inputSize}
            inputMode="number"
            label="Stock minimo"
            {...getFormProps({ name: 'manual_min_stock' })}
          />

          <Box sx={{ width: '24px' }}></Box>

          <TextField
            fullWidth
            variant={inputVariant}
            size={inputSize}
            label="Stock maximo"
            inputMode="number"
            {...getFormProps({ name: 'manual_max_stock' })}
          />
        </FlexContainer>

        <Box sx={{ mb: groupSpace }}>
          <FormControlLabel
            control={
              <Switch
                checked={prescriptionRequired}
                onChange={togglePrescriptionRequired}
              />
            }
            label="Requiere prescripción médica"
          />
          <Typography variant="body2" color="text.secondary">
            Este producto requiere una receta médica para poder venderse?{' '}
          </Typography>
        </Box>
      </CardContent>
      <CardActions>
        <Button
          type="submit"
          size="large"
          variant="contained"
          fullWidth
          endIcon={<NavigateNextIcon />}
        >
          Siguiente
        </Button>
      </CardActions>
    </form>
  )
}
