import ChipFilterAlgolia from 'components/ChipFilterAlgolia'
import FlexContainer from 'components/FlexContainer'
import React from 'react'
import { useRefinementList } from 'react-instantsearch-core'
import { isEmpty } from 'lodash'
import { Typography } from '@mui/material'
import WarningIcon from '@mui/icons-material/WarningAmberOutlined'
// import CheckIcon from '@mui/icons-material/check'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckIcon from '@mui/icons-material/Check'
import { orderBy } from 'lodash'

export const Status = {
  ONE_WEEK: {
    text: 'Menos de una semana',
    icon: CancelIcon,
    iconColor: 'error',
    textColor: 'error',
    order: 1,
  },
  TWO_WEEKS: {
    text: 'Menos de dos semana',
    icon: WarningIcon,
    iconColor: 'warning',
    textColor: 'text.primary',
    order: 2,
  },
  THREE_WEEKS: {
    text: 'Menos de tres semana',
    icon: CheckIcon,
    iconColor: 'success',
    textColor: 'text.primary',
    order: 3,
  },
  ONE_MONTH: {
    text: 'Menos de un mes',
    icon: CheckIcon,
    iconColor: 'success',
    textColor: 'text.primary',
    order: 4,
  },
  MORE_THAN_A_MONTH: {
    text: 'Mas de un mes',
    icon: InfoIcon,
    iconColor: 'info',
    textColor: 'text.primary',
    order: 5,
  },
}

export const getLabel = option => {
  if (isEmpty(option)) return ''

  const { text } = option

  return (
    <FlexContainer justifyContent="flex-start">
      <Typography sx={{ ml: 1 }} color={'text.primary'} variant="body2">
        {text}
      </Typography>
    </FlexContainer>
  )
}

const transformItems = items => {
  return items?.map((item, index) => {
    const option = Status[item.label]

    if (isEmpty(option)) return item

    return {
      ...item,
      label: getLabel(option),
      order: option.order,
    }
  })
}

export default function RotationFilter({ size = 'small', borderRadius = 1 }) {
  const { items, refine, searchForItems } = useRefinementList({
    attribute: 'sold_outin_status',
    operator: 'or',
    transformItems,
  })

  console.log({ items })

  return (
    <ChipFilterAlgolia
      hideSearchbar
      options={orderBy(items, ['order'], 'asc')}
      label="Estará agotado en"
      title={'Filtrar por rotacion'}
      defaultIndex={0}
      onChange={item => {
        refine(item)
      }}
      searchForItems={searchForItems}
      borderRadius={borderRadius}
      size={size}
    />
  )
}
