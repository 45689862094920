import ChipFilterAlgolia from 'components/ChipFilterAlgolia'
import FlexContainer from 'components/FlexContainer'
import React from 'react'
import { useRefinementList } from 'react-instantsearch-core'
import { isEmpty } from 'lodash'
import { Typography } from '@mui/material'
import WarningIcon from '@mui/icons-material/WarningAmberOutlined'
// import CheckIcon from '@mui/icons-material/check'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckIcon from '@mui/icons-material/Check'

export const Status = {
  OUT_OF_STOCK: {
    text: 'Agotado',
    icon: CancelIcon,
    iconColor: 'error',
    textColor: 'error',
  },
  LOW_STOCK: {
    text: 'Pocas unidades',
    icon: WarningIcon,
    iconColor: 'warning',
    textColor: 'text.primary',
  },
  HEALTHY_STOCK: {
    text: 'Stock sano',
    icon: CheckIcon,
    iconColor: 'success',
    textColor: 'text.primary',
  },
  EXCESSIVE_STOCK: {
    text: 'Stock excesivo',
    icon: InfoIcon,
    iconColor: 'info',
    textColor: 'text.primary',
  },
}

export const getLabel = option => {
  if (isEmpty(option)) return ''

  const { text, icon, iconColor, textColor } = option

  const Icon = icon

  return (
    <FlexContainer justifyContent="flex-start">
      <Icon fontSize="small" color={iconColor} />
      <Typography sx={{ ml: 1 }} color={textColor} variant="body2">
        {text}
      </Typography>
    </FlexContainer>
  )
}

const transformItems = items => {
  return items?.map(item => {
    const option = Status[item.label]

    if (isEmpty(option)) return item

    return {
      ...item,
      label: getLabel(option),
    }
  })
}

export default function StockFilter({ size = 'small', borderRadius = 1 }) {
  const { items, refine, searchForItems } = useRefinementList({
    attribute: 'stock_status',
    operator: 'or',
    transformItems,
  })

  return (
    <ChipFilterAlgolia
      hideSearchbar
      options={items}
      label="Stock"
      title={'Filtrar por Status'}
      defaultIndex={0}
      onChange={item => {
        refine(item)
      }}
      searchForItems={searchForItems}
      borderRadius={borderRadius}
      size={size}
    />
  )
}
