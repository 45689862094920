import * as React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import CssBaseline from '@mui/material/CssBaseline'
import Toolbar from '@mui/material/Toolbar'

const drawerWidth = 400

export default function SalesLayout({ children, resume }) {
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Box sx={{ height: '60px' }}></Box>
      <Box
        component="main"
        sx={{ width: 'calc(100vw - 513px)', bgcolor: 'background.default' }}
      >
        {children}
      </Box>
      <Drawer
        sx={{
          width: drawerWidth,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="permanent"
        anchor="right"
      >
        <Toolbar />
        {resume}
      </Drawer>
    </Box>
  )
}
