import React from 'react'
import { differenceInDays } from 'date-fns'
import { amber, red } from '@mui/material/colors'

const ONE_WEEK = 7
const ONE_MONTH = 30
const TWO_MONTHS = 60
const THREE_MONTHS = 90

const FOUR_MONTHS = 120

export const getExpirationColor = ({ expirationDate }) => {
  const today = new Date()

  const daysUntilExpiration = differenceInDays(expirationDate, today)

  const isExpired = daysUntilExpiration <= ONE_WEEK

  if (isExpired) {
    return red[500]
  } else if (daysUntilExpiration <= TWO_MONTHS) {
    return red[100]
  } else if (
    daysUntilExpiration > TWO_MONTHS &&
    daysUntilExpiration <= THREE_MONTHS
  ) {
    return amber[100]
  } else if (
    daysUntilExpiration > THREE_MONTHS &&
    daysUntilExpiration <= FOUR_MONTHS
  ) {
    return amber[50]
  } else {
    return 'transparent'
  }
}
