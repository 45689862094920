import React from 'react'
import {
  Box,
  Card,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material'
import CounterButton from 'components/CounterButton'
import Grid from '@mui/material/Unstable_Grid2' // Grid version 2
import { useTheme } from '@mui/material/styles'
import useParsers from 'hooks/useParsers'
import { cropText } from 'utils/cropText'
import FlexContainer from 'components/FlexContainer'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import DeleteIcon from '@mui/icons-material/Delete'
import { PanoramaFishEye, Search } from '@mui/icons-material'
import useModal from 'hooks/useModal'
import ProductDetail from 'features/products/components/ProductDetail'
import ShowIcon from '@mui/icons-material/VisibilityOutlined'
import { formatDate } from 'utils/formatDate'
import ExpirationRibbon from 'components/ExpirationRibbon'
import { getExpirationColor } from 'utils/getExpirationColor'

export default function ProductItem({ product, updateProduct, removeProduct }) {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const productData = product

  const tabIndex = 0

  const { parseAmount } = useParsers()
  const productDetail = useModal()

  const showProductDetail = () => {
    productDetail.open({
      data: { product_ID: product?.product_code },
    })
  }

  const fullProductName = `${product?.product_name} ${product?.pharmaceutical_form}`

  // console.log({ product })
  const hasDate = product?.default_batch?.has_an_expiration_date

  const expirationDate = hasDate
    ? product?.default_batch?.expiration_date?.toDate()
    : null

  const borderColor = hasDate
    ? getExpirationColor({ expirationDate })
    : 'transparent'

  return (
    <Card
      variant="outlined"
      sx={{
        position: 'relative',
        padding: 1,
        border: 'none',
        background: theme.palette.neutral['white'],
        border: `1px solid ${borderColor}`,
      }}
      onContextMenu={event => {
        event.preventDefault()

        handleMenu(event)
      }}
    >
      <ExpirationRibbon product={product} />

      <Box sx={{ mb: 1 }}>
        <FlexContainer>
          <Typography
            variant="subtitle1"
            color="text.primary"
            onClick={showProductDetail}
            sx={theme => ({
              cursor: 'pointer',
              '&:hover': {
                color: theme.palette.primary.main,
              },
            })}
          >
            {cropText(product?.product_name)}
          </Typography>
          <IconButton onClick={handleMenu} size="small">
            <MoreVertIcon />
          </IconButton>
        </FlexContainer>

        <Typography sx={{ mt: '-4px' }} variant="body2" color="text.secondary">
          {`${product?.pharmaceutical_form} ${product?.laboratory}`}
        </Typography>
      </Box>
      <Grid
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Box>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 'bold' }}
            color="text.primary"
          >
            {parseAmount({
              value: productData?.total,
            })}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {`P. Unit: ${parseAmount({
              value: productData?.sale_price,
            })}`}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <CounterButton
            value={productData.qty}
            max={Number(productData?.stock)}
            tabIndex={tabIndex}
            onChangeValue={qty => {
              updateProduct({
                ...productData,
                qty,
              })
            }}
            onDelete={() => {
              removeProduct(productData)
            }}
          />

          <Typography
            sx={{ mt: 0.5 }}
            align="center"
            variant="caption"
            color="text.secondary"
          >
            {`Stock: ${productData?.stock}`}
          </Typography>
        </Box>
      </Grid>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            productDetail.open({
              data: { product_ID: product?.product_code },
            })

            handleClose()
          }}
        >
          <ListItemIcon>
            <ShowIcon color="text.primary" fontSize="small" />
          </ListItemIcon>

          <ListItemText color="error">Ver detalle</ListItemText>
        </MenuItem>
        <MenuItem
          component="a"
          target="_blank"
          divider
          href={`https://www.google.com/search?q=${fullProductName}+para+que+sirve`}
        >
          <ListItemIcon>
            <Search color="text.primary" fontSize="small" />
          </ListItemIcon>

          <ListItemText color="text.primary">¿Para que sirve?</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            removeProduct(productData)
          }}
        >
          <ListItemIcon>
            <DeleteIcon color="error" fontSize="small" />
          </ListItemIcon>

          <ListItemText color="error">Remover producto</ListItemText>
        </MenuItem>
      </Menu>

      {productDetail.visible ? (
        <ProductDetail
          product_ID={productDetail?.data?.product_ID}
          onClose={productDetail.close}
        />
      ) : null}
    </Card>
  )
}
