import CreateInvoice from 'features/sales/views/CreateInvoice'
import Login from 'features/auth/views/Login'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import {
  ADMIN,
  ALL_PRODUCTS,
  AUTH,
  CREATE_INVOICE,
  LOGIN,
  LOGOUT,
  RECEIVE_ORDER,
} from 'config/routes/paths'
import PublicRoute from 'components/routes/PublicRoute'
import AdminRoute from 'components/routes/AdminRoute'
import Logout from 'features/auth/views/Logout'
import 'instantsearch.css/themes/satellite.css'
import './styles/styles.css'
import useApp from 'useApp'
import ReceiveOrder from 'features/orders/views/ReceiveOrder'
import AllProducts from 'features/products/views/AllProducts'
import Layout from 'components/Layout'

function App() {
  useApp()

  return (
    <BrowserRouter>
      <CssBaseline />
      <Routes>
        <Route
          index
          path={'/'}
          element={<Navigate to={AUTH} replace={true} />}
        />

        <Route path={AUTH} element={<PublicRoute />}>
          <Route index path={AUTH} element={<Login />} />
          <Route path={LOGIN} element={<Login />} />
          {/* <Route path={CREATE_ACCOUNT} element={<CreateAccount />} /> */}
        </Route>
        <Route path={ADMIN} element={<AdminRoute />}>
          <Route index path={ADMIN} element={<CreateInvoice />} />
          <Route index path={CREATE_INVOICE} element={<CreateInvoice />} />
          <Route index path={RECEIVE_ORDER} element={<ReceiveOrder />} />
          <Route index path={ALL_PRODUCTS} element={<AllProducts />} />

          <Route path={LOGOUT} element={<Logout />} />

          {/* <Route path={TRAVELS} element={<AllTravels />} />
           <Route path={TRAVELS_PER_MONTH} element={<TravelsPerMonth />} />
           <Route path={ADD_TRAVEL} element={<AddTravel />} />
           <Route path={LOGOUT} element={<Logout />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
