import { Button } from '@mui/material'
import React from 'react'
import { useClearRefinements } from 'react-instantsearch'

export default function RemoveFilters() {
  const { refine: removeFilters, canRefine } = useClearRefinements()

  if (!canRefine) return null

  return (
    <Button
      size="small"
      upperCase={false}
      variant="text"
      onClick={removeFilters}
    >
      Remover filtros
    </Button>
  )
}
