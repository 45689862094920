import { useEffect, useState } from 'react'
import usePharmacy from 'hooks/usePharmacy'
import { orderBy as lorderBy } from 'lodash'
import { collection, getDocs, query, where, or } from 'firebase/firestore'
import { db } from 'config/firebase'

export default function useLaboratorySelector() {
  const { selectedPharmacy } = usePharmacy()

  const [state, setState] = useState({
    loading: true,
    error: false,
    data: [],
  })

  const getData = async () => {
    try {
      const pharmacy_ID = selectedPharmacy?.pharmacy_ID

      const q = query(
        collection(db, 'laboratories'),
        or(
          where('is_global', '==', true),
          where('pharmacy_ID', '==', pharmacy_ID)
        )
      )

      const querySnapshot = await getDocs(q)

      if (querySnapshot?.empty) {
        setState({
          error: false,
          loading: false,
          data: [],
        })
      } else {
        const _data = []

        querySnapshot.forEach(documentSnapshot => {
          const response = documentSnapshot.data()
          const _item = {
            docID: documentSnapshot.id,
            ...response,
            label: response?.laboratory_name,
            value: response?.laboratory_name,
          }

          _data.push(_item)
        })

        setState({
          error: false,
          loading: false,
          data: lorderBy(_data, ['location'], ['asc']),
        })
      }
    } catch (error) {
      console.error('Error al obtener la lista de proveedores')
      console.error(error)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return { state, getData }
}
