import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { addDoc, collection } from 'firebase/firestore'
import { db } from 'config/firebase'

const INITIAL_VALUE = {
  name: '',
  phone: '',
  dni: '',
  address: '',
  note: '',
}

export default function useFormCustomers({ selectedPharmacy, onSuccess }) {
  const [showDetails, setShowDetails] = useState(false)

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: INITIAL_VALUE,
  })

  const toggleShowDetails = event => {
    setShowDetails(event.target.checked)
  }

  const onSubmit = async data => {
    try {
      await addDoc(collection(db, 'customers'), {
        ...data,
        pharmacy_ID: selectedPharmacy?.pharmacy_ID,
        created_date: new Date(),
        phone_area: selectedPharmacy?.phone_area,
        country: selectedPharmacy?.pharmacy_country,
      })

      const customer = {
        docID: '',
        name: data?.name,
        label: data?.name,
        value: data?.name,
      }

      onSuccess(customer)
    } catch (e) {
      console.error('Error al guardar cliente')
      console.error(e)
    }
  }

  const getFormProps = ({ name, rules }) => {
    return {
      name,
      control,
      errors,
      rules,
    }
  }

  return {
    showDetails,
    toggleShowDetails,
    onSubmit,
    getFormProps,
    handleSubmit,
    setValue,
    watch,
    errors,
  }
}
