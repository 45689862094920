const ACTION_KEY_DEFAULT = 'Ctrl'
// const ACTION_KEY_APPLE = '⌘'
const ACTION_KEY_APPLE = 'Ctrl'

export function isAppleDevice() {
  return /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)
}

export const getShortcutPrefix = () => {
  if (typeof navigator === 'undefined') return ''

  return isAppleDevice() ? ACTION_KEY_APPLE : ACTION_KEY_DEFAULT
}
