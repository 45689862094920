import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from '@mui/material'
import React, { useRef } from 'react'
import Grid from '@mui/material/Unstable_Grid2' // Grid version 2
import ButtonWithShortcut from 'components/ButtonWithShortcut'
import { getShortcutPrefix } from 'utils/getShortcutPrefix'
import { useKeyboardEvents } from 'hooks/useKeyboardEvents'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import usePayInvoice from 'hooks/usePayInvoice'
import { uniqueId } from 'lodash'
import CalendarIcon from '@mui/icons-material/CalendarMonth'
import { format, subDays } from 'date-fns'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import ProformIcon from '@mui/icons-material/Receipt'
import CashSaleIcon from '@mui/icons-material/PointOfSale'
import CreditIcon from '@mui/icons-material/AccountBalanceWallet'
import CashIcon from '@mui/icons-material/Payments'
import CustomerSelector from 'components/CustomerSelector'

const itemSpace = 0.5
const blockSpace = 3

const inputSize = 'small'
const iconSize = 'small'
const iconStyles = {
  mr: 1,
}

const todayString = format(new Date(), 'yyyy-MM-dd')
const yesterdayString = format(subDays(new Date(), 1), 'yyyy-MM-dd')

export default function InvoiceResume({
  currentInvoice,
  productsCount,
  subtotal,
  processInvoice,
  clearProductList,
}) {
  const theme = useTheme()

  const invoiceProps = {
    createNewInvoice: processInvoice,
    subtotal: subtotal,
    loading: false,
    productsCount,
    clearProductList,
  }

  const inputRef = useRef(null)

  const invoice1 = usePayInvoice(invoiceProps)
  const invoice2 = usePayInvoice(invoiceProps)
  const invoice3 = usePayInvoice(invoiceProps)

  const allInvoices = {
    invoice_1: invoice1,
    invoice_2: invoice2,
    invoice_3: invoice3,
  }

  const selectedInvoice = allInvoices[currentInvoice]

  const {
    onSubmit,
    selectedCurrency,
    setSelectedCurrency,
    paymentMethod,
    setPaymentMethod,
    customerPayWith,
    changeToReturn,
    parseAmount,
    currencies,
    applyDiscount,
    toggleApplyDiscount,
    primaryCurrency,
    totalToPay,
    discountType,
    setDiscountType,
    discountSummary,
    customers,
    getCustomers,
    typeOfInvoice,
    setTypeOfInvoice,
    invoiceDate,
    setInvoiceDate,
    viewCustomer,
    handleViewCustomer,
    printReceipt,
    handlePrintReceipt,
    secondaryCurrency,
    customer,
    setCustomer,
    clientPayment,
    setClientPayment,
    exchangeRate,
    setExchangeRate,
    discountAmount,
    setDiscountAmount,
    percentageDiscount,
    setPercentageDiscount,
    cancelInvoice,
  } = selectedInvoice

  const invoiceDateString = format(invoiceDate, 'yyyy-MM-dd')

  const action = () => {
    inputRef?.current?.focus()
  }

  // useKeyboardEvents({
  //   keyCode: 71,
  //   action: action,
  //   ctrlKey: true,
  //   shiftKey: false,
  //   altKey: false,
  //   ref: inputRef,
  // })

  const isToday = invoiceDateString === todayString
  const isYesterday = invoiceDateString === yesterdayString

  const InvoicesLabels = {
    invoice_1: '#1',
    invoice_2: '#2',
    invoice_3: '#3',
  }

  return (
    <Card
      elevation={0}
      sx={{
        background: '#fff',
        width: '100%',
        height: '100%',
        overflow: 'auto !important',
        '-ms-overflow-style': 'none' /* for Internet Explorer, Edge */,
        'scrollbar-width': 'none' /* for Firefox */,
        'overflow-y': 'scroll',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <CardContent sx={{ pt: 0 }}>
        <Typography sx={{ mb: 1 }} variant="h6">
          {`Resumen Factura ${InvoicesLabels[currentInvoice]}`}
        </Typography>
        <Box sx={{ mb: blockSpace }}>
          <Typography sx={{ mb: itemSpace }} variant="subtitle1">
            Fecha de la venta
          </Typography>

          <MobileDatePicker
            label=""
            // closeOnSelect
            disableFuture
            defaultValue={invoiceDate}
            value={invoiceDate}
            maxDate={new Date()}
            format="EEEE d MMM yyyy"
            slotProps={{
              toolbar: { toolbarFormat: 'EEE d MMM yyyy', hidden: false },
              textField: {
                hiddenLabel: true,
                fullWidth: true,
                variant: 'filled',
                size: inputSize,
                InputProps: {
                  startAdornment: isToday ? (
                    <InputAdornment position={'start'}>Hoy:</InputAdornment>
                  ) : isYesterday ? (
                    <InputAdornment position={'start'}>Ayer:</InputAdornment>
                  ) : null,
                  endAdornment: (
                    <InputAdornment position={'end'}>
                      <CalendarIcon />
                    </InputAdornment>
                  ),
                },
              },
            }}
            onAccept={value => {
              setInvoiceDate(value)
            }}
          />
        </Box>
        <Box sx={{ mb: blockSpace }}>
          <Typography sx={{ mb: itemSpace }} variant="subtitle1">
            Venta al
          </Typography>
          <ToggleButtonGroup
            fullWidth
            color="primary"
            size={inputSize}
            value={typeOfInvoice}
            exclusive
            onChange={(_, value) => {
              if (value) {
                setTypeOfInvoice(value)
              }
            }}
            aria-label="Platform"
            tabIndex={100}
          >
            <ToggleButton value="Contado">
              <CashSaleIcon fontSize={iconSize} sx={iconStyles} /> Contado
            </ToggleButton>
            <ToggleButton value="Credito">
              <CreditIcon fontSize={iconSize} sx={iconStyles} /> Credito
            </ToggleButton>
            <ToggleButton value="Proforma">
              <ProformIcon fontSize={iconSize} sx={iconStyles} /> Proforma
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        {typeOfInvoice === 'Contado' ? (
          <Box sx={{ mb: blockSpace }}>
            <Typography sx={{ mb: itemSpace }} variant="subtitle1">
              Método de pago
            </Typography>
            <ToggleButtonGroup
              fullWidth
              size={inputSize}
              color="primary"
              value={paymentMethod}
              exclusive
              onChange={(_, value) => {
                if (value) {
                  setPaymentMethod(value)
                }
              }}
              aria-label="Platform"
              tabIndex={100}
            >
              <ToggleButton value="Efectivo">
                <CashIcon fontSize={iconSize} sx={iconStyles} /> Efectivo
              </ToggleButton>
              <ToggleButton value="Tarjeta">
                <CreditCardIcon fontSize={iconSize} sx={iconStyles} /> Tarjeta
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        ) : null}
        {typeOfInvoice === 'Contado' && paymentMethod === 'Efectivo' ? (
          <Box sx={{ mb: blockSpace }}>
            <Typography sx={{ mb: itemSpace }} variant="subtitle1">
              El cliente paga con
            </Typography>
            <Grid container spacing={1}>
              <Grid xs={4}>
                <FormControl variant="filled" fullWidth>
                  <InputLabel>Moneda</InputLabel>
                  <Select
                    label="Moneda"
                    fullWidth
                    variant="filled"
                    size={inputSize}
                    value={selectedCurrency}
                    onChange={event => {
                      const type = event.target.value

                      setSelectedCurrency(type)
                    }}
                  >
                    {currencies?.map(currency => {
                      return (
                        <MenuItem key={uniqueId()} value={currency?.type}>
                          {currency?.label}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={selectedCurrency === 'primary' ? 8 : 4}>
                <TextField
                  inputRef={inputRef}
                  fullWidth
                  variant="filled"
                  size={inputSize}
                  label="Paga con"
                  type="number"
                  inputProps={{
                    min: totalToPay,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position={'start'}>
                        {selectedCurrency === 'primary'
                          ? primaryCurrency?.simbol
                          : secondaryCurrency?.simbol}
                      </InputAdornment>
                    ),
                  }}
                  value={clientPayment}
                  onChange={event => {
                    setClientPayment(event?.target?.value)
                  }}
                />
              </Grid>
              {selectedCurrency === 'secondary' ? (
                <Grid xs={4}>
                  <TextField
                    type="number"
                    inputRef={inputRef}
                    fullWidth
                    variant="filled"
                    size={inputSize}
                    label="Tipo de cambio"
                    value={exchangeRate}
                    onChange={event => {
                      setExchangeRate(event?.target?.value)
                    }}
                    inputProps={{
                      min: 0,
                    }}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Box>
        ) : null}
        <Box>
          {typeOfInvoice !== 'Credito' ? (
            <FormControlLabel
              tabIndex={100}
              control={
                <Switch checked={viewCustomer} onChange={handleViewCustomer} />
              }
              label="Agregar cliente"
            />
          ) : null}

          {typeOfInvoice === 'Credito' || viewCustomer ? (
            <Box sx={{ mt: itemSpace, mb: blockSpace }}>
              {typeOfInvoice === 'Credito' ? (
                <Typography sx={{ mb: itemSpace }} variant="subtitle1">
                  Cliente a quien se le otorgó el crédito
                </Typography>
              ) : null}

              <CustomerSelector
                data={customers?.data}
                getData={getCustomers}
                value={customer}
                setValue={setCustomer}
              />
            </Box>
          ) : null}

          <FormControlLabel
            tabIndex={100}
            control={
              <Switch checked={applyDiscount} onChange={toggleApplyDiscount} />
            }
            label="Aplicar descuento?"
          />
          {applyDiscount ? (
            <Box sx={{ mt: itemSpace, mb: blockSpace }}>
              <Grid container spacing={1}>
                <Grid xs={4}>
                  <FormControl variant="filled" fullWidth>
                    <InputLabel>Descuento en</InputLabel>
                    <Select
                      fullWidth
                      variant="filled"
                      size={inputSize}
                      value={discountType}
                      onChange={event => {
                        const value = event.target.value

                        setDiscountType(value)
                      }}
                      tabIndex={100}
                    >
                      <MenuItem value={'AMOUNT'}>
                        {primaryCurrency?.label}
                      </MenuItem>
                      <MenuItem value={'PERCENTAGE'}>%</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid xs={8}>
                  {discountType === 'PERCENTAGE' ? (
                    <TextField
                      fullWidth
                      variant="filled"
                      type="number"
                      size={inputSize}
                      label={'Tasa de descuento'}
                      tabIndex={100}
                      inputProps={{
                        min: 0,
                        max: 100,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position={'end'}>%</InputAdornment>
                        ),
                      }}
                      value={percentageDiscount}
                      onChange={event => {
                        setPercentageDiscount(event?.target?.value)
                      }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      type="number"
                      variant="filled"
                      size={inputSize}
                      label={`Descuento en ${primaryCurrency?.label}`}
                      tabIndex={100}
                      inputProps={{
                        min: 0,
                        max: totalToPay,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position={'start'}>
                            {primaryCurrency?.simbol}
                          </InputAdornment>
                        ),
                      }}
                      value={discountAmount}
                      onChange={event => {
                        setDiscountAmount(event?.target?.value)
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          ) : null}

          {/* {typeOfInvoice === 'Contado' ? (
            <FormControlLabel
              control={
                <Switch checked={printReceipt} onChange={handlePrintReceipt} />
              }
              label="Imprimir el recibo"
            />
          ) : null} */}
        </Box>
        {/* <Divider /> */}
        {/* <Typography variant="h6">Resumen</Typography> */}

        <Divider
          sx={{
            mt: itemSpace,
          }}
        />

        <Box
          sx={{
            mb: blockSpace,
            padding: 2,
            // background: theme.palette.neutral[100],
          }}
        >
          {/* <Typography variant="h6">Resumen</Typography> */}

          <Grid
            container
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{ mb: itemSpace }}
          >
            <Typography variant="body1">Monto de la factura</Typography>
            <Typography variant="body1">
              {parseAmount({ value: subtotal })}
            </Typography>
          </Grid>

          {applyDiscount ? (
            <Grid
              container
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Typography variant="body1">{`Descuento (${discountSummary.percentage}%)`}</Typography>
              <Typography variant="body1">
                {parseAmount({ value: discountSummary?.amount })}
              </Typography>
            </Grid>
          ) : null}

          {typeOfInvoice === 'Contado' && selectedCurrency === 'secondary' ? (
            <Grid
              container
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              sx={{ mb: itemSpace }}
            >
              <Typography variant="body1">Paga con</Typography>
              <Typography variant="body1">
                {parseAmount({ value: customerPayWith })}
              </Typography>
            </Grid>
          ) : null}

          {/* <Divider /> */}
          {typeOfInvoice === 'Contado' ? (
            <Box>
              <Grid
                container
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                sx={{ mb: itemSpace }}
              >
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Total a pagar
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {parseAmount({ value: totalToPay })}
                </Typography>
              </Grid>
              {customerPayWith > 0 ? (
                <Grid
                  container
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  sx={{ mb: itemSpace }}
                >
                  <Typography variant="body1">Cambio</Typography>
                  <Typography variant="body1">
                    {parseAmount({ value: changeToReturn })}
                  </Typography>
                </Grid>
              ) : null}
            </Box>
          ) : (
            <Grid
              container
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              sx={{ mb: itemSpace }}
            >
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                Total a pagar
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                {parseAmount({ value: totalToPay })}
              </Typography>
            </Grid>
          )}
        </Box>
        <Box>
          <ButtonWithShortcut
            onClick={onSubmit}
            type="submit"
            keyCode={83}
            keyLabel="S"
            size="large"
          >
            {typeOfInvoice === 'Contado'
              ? 'Pagar ahora'
              : typeOfInvoice === 'Credito'
              ? 'Guardar factura'
              : 'Guardar Proforma'}
          </ButtonWithShortcut>
          <Button
            sx={{ mt: 2 }}
            fullWidth
            color="error"
            variant="text"
            onClick={cancelInvoice}
          >
            Anular factura
          </Button>
        </Box>
      </CardContent>
    </Card>
  )
}
