import { Navigate, Outlet } from 'react-router-dom'
import { ADMIN } from 'config/routes/paths.js'
import useAuthContext from 'features/auth/hooks/useAuthContext'

export default function PublicRoute() {
  const { isAuth } = useAuthContext()

  if (isAuth) return <Navigate to={ADMIN} />

  //Pintar el componente que haga match con la url a la que nos estamos intentando dirigir
  return (
    <div>
      <Outlet />
    </div>
  )
}
