import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSearchBox } from 'react-instantsearch'
import { debounce } from 'lodash'
import {
  Box,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputBase,
  Menu,
  Switch,
  Typography,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import FlexContainer from 'components/FlexContainer'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'

const ArrowUp = 38

export default function SearchBox(props) {
  const { query, refine } = useSearchBox(props)
  const [inputValue, setInputValue] = useState(query)
  const {
    initialKeyword = '',
    closeOnSelect = false,
    setCloseOnSelect = () => {},
    showFilters = false,
    setShowFilters = () => {},
    searchbarRef = null,
    variant = 'standard',
    showMoreButton = true,
    size = 'medium',
    padding = 2,
  } = props

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const sendQuery = useCallback(
    searchTerm => {
      refine(searchTerm)
    },
    [refine]
  )

  const debouncedSendQuery = useMemo(() => {
    return debounce(sendQuery, 500)
  }, [sendQuery])

  const handleChange = event => {
    const newQuery = event.target.value

    setInputValue(newQuery)
    debouncedSendQuery(newQuery)
  }

  const onClear = () => {
    setInputValue('')
    refine('')
    searchbarRef?.current?.focus()
  }

  useEffect(() => {
    if (Boolean(initialKeyword)) {
      setInputValue(initialKeyword)
      refine(initialKeyword)
    }
  }, [initialKeyword])

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: padding,
      }}
    >
      <SearchIcon color={'primary'} fontSize={size} />

      <InputBase
        size={size}
        autoFocus
        fullWidth
        variant={variant}
        inputRef={searchbarRef}
        placeholder="Buscar producto o categoría"
        autoCorrect={'false'}
        spellCheck={'false'}
        autoComplete="off"
        value={inputValue}
        onChange={handleChange}
        sx={{
          paddingLeft: 2,
        }}
        onKeyDown={event => {
          if (event.keyCode === ArrowUp) {
            event.preventDefault()
          }
        }}
        endAdornment={
          <InputAdornment position="end">
            <FlexContainer>
              {inputValue.length > 0 ? (
                <IconButton onClick={onClear}>
                  <HighlightOffOutlinedIcon />
                </IconButton>
              ) : null}

              {showMoreButton ? (
                <IconButton
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                >
                  <MoreVertIcon />
                </IconButton>
              ) : null}
            </FlexContainer>
          </InputAdornment>
        }
        onDoubleClick={() => {
          searchbarRef?.current?.select()
        }}
      />

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{
          '.MuiMenu-paper': {
            padding: 1,
          },
        }}
      >
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          Ajustes
        </Typography>
        <Box>
          <FormControlLabel
            fullWidth
            control={
              <Switch
                checked={showFilters}
                onChange={event => {
                  setShowFilters(event.target.checked)
                }}
              />
            }
            label="Mostrar filtros"
          />
        </Box>
        <Box>
          <FormControlLabel
            fullWidth
            control={
              <Switch
                checked={closeOnSelect}
                onChange={event => {
                  setCloseOnSelect(event.target.checked)
                }}
              />
            }
            label="Cerrar al seleccionar"
          />
        </Box>
      </Menu>
    </Box>
  )
}
