import { db } from 'config/firebase'
import { query, where, collection, getDocs, limit } from 'firebase/firestore'

export const searchProductInDB = async ({ code, selectedPharmacy }) => {
  try {
    const pharmacy_ID = selectedPharmacy?.pharmacy_ID

    let documentSnapshot

    const q = query(
      collection(db, 'products'),
      where('pharmacy_ID', '==', pharmacy_ID),
      where('product_code', '==', code),
      limit(1)
    )

    const querySnapshot = await getDocs(q)

    querySnapshot.forEach(docSnapshot => {
      if (!docSnapshot.exists) return

      documentSnapshot = docSnapshot
    })

    return documentSnapshot
  } catch (err) {
    console.error('Error al obtener el producto de la base de datos')

    console.error(err)
  }
}
