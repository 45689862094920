export const cropText = (string, length = 30) => {
  if (typeof string !== 'string') return ''
  if (!string) return ''

  const MAX_LENGTH = length

  if (string.length <= MAX_LENGTH) return string

  const text = string.slice(0, MAX_LENGTH)

  return `${text}...`
}
