import { formatDistanceToNow } from 'date-fns'
import React from 'react'
import { es } from 'date-fns/locale/es'
import { formatDate } from 'utils/formatDate'
import { Box } from '@mui/material'
import { differenceInDays } from 'date-fns'
import ExpirationChip from 'components/ExpirationChip'
import { getExpirationColor } from 'utils/getExpirationColor'

const THREE_MONTHS = 90

export default function ExpirationRibbon({ product }) {
  const hasDate = product?.default_batch?.has_an_expiration_date

  if (!hasDate) return null

  const expirationDate = product?.default_batch?.expiration_date?.toDate()

  const dateRelative = hasDate
    ? formatDistanceToNow(expirationDate, {
        addSuffix: true,
        locale: es,
      })
    : ''

  const expirationDateString = hasDate
    ? formatDate(expirationDate, 'MMM yyyy')
    : ''

  const label = hasDate ? `${expirationDateString} (${dateRelative})` : 'N/D'

  const daysUntilExpiration = differenceInDays(expirationDate, new Date())

  if (!hasDate || daysUntilExpiration > THREE_MONTHS) return null

  const bgColor = getExpirationColor({ expirationDate })

  return (
    <Box
      sx={{
        position: 'absolute',
        background: bgColor,
        width: '100%',
        bottom: 0,
        left: 0,
        right: 0,
        borderTopLeftRadius: 1,
        borderTopRightRadius: 1,
        padding: 0.5,
      }}
    >
      <ExpirationChip
        label={`Vencimiento: ${label}`}
        expirationDate={expirationDate}
      />
    </Box>
  )
}
