import { CopyAll } from '@mui/icons-material'
import { IconButton, Typography } from '@mui/material'
import { useCopyToClipboard } from '@uidotdev/usehooks'
import FlexContainer from 'components/FlexContainer'
import React from 'react'

export default function TextGroup({
  direction = 'row',
  label,
  value,
  mt = 0,
  mb = 2,
  ml = 0,
  mr = 0,
  alignItems = direction === 'row' ? 'center' : 'flex-start',
  justifyContent = direction === 'row' ? 'space-between' : 'center',
  copyValue = false,
}) {
  const [copiedText, copyToClipboard] = useCopyToClipboard()

  return (
    <FlexContainer
      mt={mt}
      mb={mb}
      ml={ml}
      mr={mr}
      flexDirection={direction}
      alignItems={alignItems}
      justifyContent={justifyContent}
    >
      <Typography variant="body2" color="text.secondary">
        {label} {direction === 'row' ? ':' : ''}
      </Typography>
      {copyValue ? (
        <FlexContainer>
          <Typography
            ml={direction === 'row' ? 2 : 0}
            variant="subtitle1"
            color="text.primary"
          >
            {value}
          </Typography>
          <IconButton onClick={() => copyToClipboard(value)}>
            <CopyAll />
          </IconButton>
        </FlexContainer>
      ) : (
        <Typography
          ml={direction === 'row' ? 2 : 0}
          variant="subtitle1"
          color="text.primary"
        >
          {value}
        </Typography>
      )}
    </FlexContainer>
  )
}
