import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import AccountCircle from '@mui/icons-material/AccountCircle'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import { useNavigate } from 'react-router-dom'
import { CREATE_INVOICE, LOGOUT } from 'config/routes/paths'
import usePharmacy from 'hooks/usePharmacy'
import StorefrontIcon from '@mui/icons-material/Storefront'
import { getUserById } from 'utils/getUserById'
import { auth } from 'config/firebase'
import { Button, Chip, Stack, Tooltip, useTheme } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'
import Avatar from 'react-avatar'
import ProductIcon from '@mui/icons-material/Inventory2Outlined'
import NavigationMenu from 'components/NavigationMenu'
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCartOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import MenuIcon from '@mui/icons-material/Menu'

export default function MenuAppBar({ openDrawer = true, handleDrawerOpen }) {
  // const navigate = useNavigate()
  const { users, selectedPharmacy } = usePharmacy()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const theme = useTheme()

  const open = Boolean(anchorEl)

  const handleMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const signOut = () => {
    // navigate(LOGOUT)
  }

  const quickSale = () => {
    // navigate(CREATE_INVOICE)
  }

  const user = auth.currentUser
  const currentUser = getUserById({ users, user_ID: user?.uid })

  const userName = currentUser?.user_name

  return (
    <AppBar
      variant="outlined"
      position="fixed"
      color="transparent"
      elevation={0}
      size="small"
      // sx={{
      //   minHeight: '40px !important',
      // }}
    >
      <Toolbar variant="dense">
        <Box
        // sx={{
        //   mr: 'auto',
        // }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(openDrawer && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <StorefrontIcon fontSize="small" />
            <Typography
              variant="body1"
              component="div"
              sx={{ ml: 1, flexGrow: 1 }}
            >
              {selectedPharmacy?.pharmacy_name}
            </Typography>
          </Box>
          {/* <NavigationMenu /> */}
        </Box>

        <Box sx={{ ml: 'auto', display: 'flex', alignItems: 'center' }}>
          {/* <Button
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={quickSale}
            startIcon={<PointOfSaleIcon fontSize="large" />}
            sx={{ mr: 2 }}
            size="small"
          >
            Venta rapida
          </Button> */}

          <IconButton sx={{ mx: 1 }}>
            <ShoppingCartIcon color={theme.palette.text.primary} />
          </IconButton>
          <IconButton sx={{ mx: 1 }} color="text.primary">
            <NotificationsNoneOutlinedIcon />
          </IconButton>
          {/* <IconButton sx={{ mx: 2 }}>
            <NotificationsNoneOutlinedIcon color="text.primary" />
          </IconButton> */}
          <IconButton sx={{ mx: 1 }} color={theme.palette.text.primary}>
            <SettingsOutlinedIcon />
          </IconButton>
          <IconButton color="primary">
            <PointOfSaleIcon />
          </IconButton>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              cursor: 'pointer',
              padding: 1,
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
                borderRadius: 1,
              },
            }}
            onClick={handleMenu}
            size="small"
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar
              name={userName || 'Farmagestor Usuario'}
              email={currentUser?.user_email || 'Email'}
              size="20"
              color="#bdbdbd"
              round="30px"
            />
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                variant="subtitle1"
                color="text.primary"
                sx={{ minWidth: 100, ml: 1, mr: 0.5 }}
              >
                {userName}
              </Typography>
              <KeyboardArrowDownIcon color={theme.palette.text.primary} />
            </Box>
          </Box>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={signOut}>Cerrar sesión</MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  )
}
