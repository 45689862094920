import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import { useNavigate } from 'react-router-dom'
import { CREATE_INVOICE, LOGOUT } from 'config/routes/paths'
import usePharmacy from 'hooks/usePharmacy'
import { useTheme } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Avatar from 'react-avatar'
import { getUserById } from 'utils/getUserById'
import { auth } from 'config/firebase'
import StorefrontIcon from '@mui/icons-material/Storefront'

export default function PharmacySelector() {
  const { users, selectedPharmacy } = usePharmacy()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const theme = useTheme()

  const open = Boolean(anchorEl)

  const handleMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const signOut = () => {
    // navigate(LOGOUT)
  }

  const user = auth.currentUser
  const currentUser = getUserById({ users, user_ID: user?.uid })

  const userName = currentUser?.user_name

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          cursor: 'pointer',
          padding: 1,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
            borderRadius: 1,
          },
        }}
        onClick={handleMenu}
        size="small"
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <StorefrontIcon fontSize="small" />

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant="subtitle1"
            color="text.primary"
            sx={{ minWidth: 100, ml: 1, mr: 0.5 }}
          >
            {selectedPharmacy?.pharmacy_name}
          </Typography>
          <KeyboardArrowDownIcon color={theme.palette.text.primary} />
        </Box>
      </Box>
      {/* <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={signOut}>Cerrar sesión</MenuItem>
      </Menu> */}
    </Box>
  )
}
