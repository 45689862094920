import { getDataFromRef } from 'utils/getDataFromRef'
import { TypesOftransactions } from 'const/TypesOftransactions'
import { isDate } from 'lodash'
import { numberIsValid } from 'utils/numbers/numberIsValid'
import { getStockStatus } from './getStockStatus'
import { db } from 'config/firebase'
import { writeBatch, doc } from 'firebase/firestore'
import { getBatches } from './getBatches'
import { getProductStatistics } from './getProductStatistics'

const Actions = {
  SALE: 'Vendido recientemente.',
  LOSS: 'Merma recientemente.',
  CUSTOMER_RETURN: 'Un cliente hizo una devolución.',
  RETURN_TO_SUPPLIER: 'Se realizó una devolución al proveedor.',
  ORDER: 'Se recibió un pedido.',
}

export const increaseProductStock = async ({
  product_ref,
  pharmacy_ID,
  increase,
  transaction_type = TypesOftransactions.ORDER,
  sourcing_time = 2,
  new_cost,
  new_supplier_name,
  new_supplier_ID,
  current_price = 0,
  new_location = '',
}) => {
  try {
    const product = await getDataFromRef(product_ref)

    const getPrevNumber = property => {
      const value = product[property]

      return numberIsValid(value) ? value : 0
    }

    const getPrevDate = property => {
      const date = product[property]

      return isDate(date) ? date : ''
    }

    const getPrevString = property => {
      const string = product[property]

      return Boolean(string) ? string : ''
    }

    const getValue = ({ type, prev_value }) => {
      const new_value =
        transaction_type === type
          ? Number(prev_value) + Number(increase)
          : prev_value

      return numberIsValid(new_value) ? new_value : 0
    }

    const prev_stock = getPrevNumber('stock')
    const prev_average_sourcing_time = getPrevNumber('sourcing_time')
    const prev_location = getPrevString('location')

    const prev_price = getPrevNumber('current_price')
    const new_stock = Number(prev_stock) + Number(increase)

    const prev_inputs = getPrevNumber('inputs')
    const new_inputs = Number(prev_inputs) + Number(increase)

    const new_average_sourcing_time =
      transaction_type === TypesOftransactions.ORDER
        ? sourcing_time
        : prev_average_sourcing_time

    const last_order =
      transaction_type === TypesOftransactions.ORDER
        ? new Date()
        : getPrevDate('last_order')

    const last_customer_return =
      transaction_type === TypesOftransactions.CUSTOMER_RETURN
        ? new Date()
        : getPrevDate('last_customer_return')

    const prev_units_purchased = getPrevNumber('units_purchased')
    const prev_units_returned_by_customers = getPrevNumber(
      'units_returned_by_customers'
    )

    const supplier_ID = new_supplier_ID
      ? new_supplier_ID
      : getPrevString('supplier_ID')

    const supplier_name = new_supplier_name
      ? new_supplier_name
      : getPrevString('supplier_name')

    const cost = numberIsValid(new_cost) ? new_cost : getPrevNumber('cost')

    const last_action = Actions[transaction_type]

    const manual_min_stock = numberIsValid(Number(product?.manual_min_stock))
      ? Number(product?.manual_min_stock)
      : 0

    const manual_max_stock = numberIsValid(Number(product?.manual_max_stock))
      ? Number(product?.manual_max_stock)
      : 0

    const low_stock = new_stock < manual_min_stock
    const excessive_stock = new_stock > manual_max_stock

    const stock_status = getStockStatus({
      stock: new_stock,
      low_stock,
      excessive_stock,
    })

    const { default_batch } = await getBatches({
      pharmacy_ID,
      product_ref,
    })

    const stats = await getProductStatistics({
      pharmacy_ID,
      product_ID: product?.doc_ID,
      stock: new_stock,
    })

    const productStats = {
      average_per_day: stats?.count?.day,
      average_per_week: stats?.count?.week,
      average_per_month: stats?.count?.month,
      sold_last_3months: stats?.count?.total,
      sold_out_in: stats?.soldOutIn?.days,
      sold_outin_status: stats?.soldOutIn?.status,
      stats,
    }

    const update = {
      default_batch,
      expiration_date: default_batch?.expiration_date || '',
      stock: new_stock,
      current_price: numberIsValid(current_price) ? current_price : prev_price,
      stock_status,
      location: Boolean(new_location) ? new_location : prev_location,
      inputs: new_inputs,
      low_stock,
      excessive_stock,
      units_purchased: getValue({
        type: TypesOftransactions.ORDER,
        prev_value: prev_units_purchased,
      }),
      units_returned_by_customers: getValue({
        type: TypesOftransactions.CUSTOMER_RETURN,
        prev_value: prev_units_returned_by_customers,
      }),
      sourcing_time: new_average_sourcing_time,
      last_order: last_order,
      last_customer_return: last_customer_return,
      supplier_ID: supplier_ID,
      supplier_name: supplier_name,
      cost: cost,
      modified_date: new Date(),
      last_action: last_action,
      ...productStats,
    }

    const productRef = doc(db, 'products', product?.doc_ID)

    // Get a new write batch
    const batch = writeBatch(db)

    batch.update(productRef, update)

    await batch.commit()
  } catch (err) {
    console.error(
      'error al actualizar el precio y el stock del producto --increaseProductStock'
    )

    console.error(err)
  }
}
