import React from 'react'
import useFormCustomers from './useFormCustomers'
import usePharmacy from 'hooks/usePharmacy'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  InputAdornment,
  Switch,
  TextField,
} from '@mui/material'
import { Controller } from 'react-hook-form'

const textFieldVariant = 'outlined'

export default function FormCustomers({ onClose, onSuccess }) {
  const { selectedPharmacy } = usePharmacy()

  const {
    getFormProps,
    handleSubmit,
    onSubmit,
    showDetails,
    toggleShowDetails,
    setValue,
    watch,
    errors,
  } = useFormCustomers({
    onSuccess,
    selectedPharmacy,
  })

  return (
    <Dialog open={true} onClose={onClose} maxWidth="xs" fullWidth={true}>
      <DialogTitle>Agregar cliente</DialogTitle>
      <Divider />
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            {...getFormProps({
              name: 'name',
              rules: {
                required: true,
              },
            })}
            render={({ field }) => (
              <TextField
                {...field}
                sx={{ mb: 2 }}
                fullWidth
                variant={textFieldVariant}
                label="Nombre del cliente"
                error={errors['name']}
                helperText={errors['name'] ? 'Nombre requerido' : ''}
              />
            )}
          />

          <FormControlLabel
            tabIndex={100}
            sx={{ mb: 2 }}
            control={
              <Switch checked={showDetails} onChange={toggleShowDetails} />
            }
            label="Información adicional"
          />

          {showDetails ? (
            <>
              <Controller
                {...getFormProps({
                  name: 'phone',
                  rules: { required: false },
                })}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    inputMode="tel"
                    label="Número de teléfono (Opcional)"
                    sx={{
                      mt: 2,
                      mb: 2,
                    }}
                    variant={textFieldVariant}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position={'start'}>
                          {selectedPharmacy?.phone_area}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />

              <Controller
                {...getFormProps({
                  name: 'dni',
                  rules: { required: false },
                })}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Número de identificación (Opcional)"
                    sx={{ mb: 2 }}
                    variant={textFieldVariant}
                  />
                )}
              />
              <Controller
                {...getFormProps({
                  name: 'address',
                  rules: { required: false },
                })}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    sx={{ mb: 2 }}
                    label="Dirección (Opcional)"
                    variant={textFieldVariant}
                  />
                )}
              />
              <Controller
                {...getFormProps({
                  name: 'note',
                  rules: { required: false },
                })}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    sx={{ mb: 3 }}
                    label="Nota (Opcional)"
                    variant={textFieldVariant}
                  />
                )}
              />
            </>
          ) : null}
          <Button size="large" variant="contained" fullWidth type="submit">
            Guardar
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  )
}
