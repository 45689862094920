import { getAverageSalesPerDay } from './getAverageSalesPerDay'
import { floor } from 'lodash'

export const getAverageSalesPerWeek = async product => {
  const { averageSalesPerDay } = await getAverageSalesPerDay(product)
  const averageSalesPerWeek = averageSalesPerDay * 7

  const result = floor(averageSalesPerWeek, 2)

  return result
}
