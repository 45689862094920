import { useState } from 'react'

export default function useModal() {
  const [modal, setModal] = useState({ visible: false, data: {} })

  const open = ({ data = {} } = {}) => {
    setModal({ visible: true, data })
  }

  const close = () => {
    setModal(prevValue => ({ ...prevValue, visible: false }))
  }

  return {
    open,
    close,
    visible: modal.visible,
    data: modal?.data,
  }
}
