import { upperCase } from 'lodash'
import React, { useMemo, useState } from 'react'
import { floor, isEmpty } from 'lodash'

const Units = {
  ml: 'ml',
  drops: 'Gotas',
  teaspoons: 'Cucharaditas',
}

const FrequencyData = {
  8: {
    label: 'Cada 3 horas',
    value: 8,
  },
  6: {
    label: 'Cada 4 horas',
    value: 6,
  },
  4: {
    label: 'Cada 6 horas',
    value: 4,
  },
  3: {
    label: 'Cada 8 horas',
    value: 3,
  },
  2: {
    label: 'cada 12 horas',
    value: 2,
  },
  1: {
    label: 'Una vez al día',
    value: 1,
  },
}

const convertMlToDrops = ml => {
  return Number(ml) * 20
}

const convertDropsToML = drops => {
  return Number(drops) * 0.05
}

const convertMlToTeaspoons = ml => {
  return Number(ml) / 5
}

const convertTeaspoonsToML = teaspoons => {
  return Number(teaspoons) * 5
}

const getResults = ({ resultInMl, frequency, dayOrDose }) => {
  const frequencyLabel = FrequencyData[frequency].label

  const totalDose = resultInMl * frequency

  const mlParsed = floor(resultInMl, 2)

  const labelPerDose = `Tomar ${mlParsed}ml en cada dosis`
  const labelPerDay = `${mlParsed}ml ${frequencyLabel}, total al dia: ${totalDose}ml`

  return {
    ml: resultInMl,
    drops: convertMlToDrops(resultInMl),
    teaspoons: convertMlToTeaspoons(resultInMl),
    label: dayOrDose === 'DOSE' ? labelPerDose : labelPerDay,
  }
}

const convertKgToPound = kg => {
  return Number(kg) * 2.20462
}
const convertPoundToKg = pounds => {
  return Number(pounds) * 0.45359
}

export default function useDosageCalculator() {
  const [weightIn, setWeightIn] = useState('Kg')
  const [resultIn, setResultIn] = useState('ml')

  const [patientWeight, setPatientWeight] = useState('')
  const [concentrationInMG, setConcentrationInMG] = useState('')
  const [concentrationInML, setConcentrationInML] = useState(5)
  const [doctorIndication, setDoctorIndication] = useState('')
  const [dayOrDose, setDayOrDose] = useState('DOSE')
  const [frequency, setFrequency] = useState(1)

  const [results, setResults] = useState({})

  // const weightInKilograms = useMemo(() => {
  //   if (weightIn === 'KG') return Number(patientWeight)

  //   return convertPoundToKg(Number(patientWeight))
  // }, [weightIn])

  // const calculateDosage = () => {
  //   let weightInKilograms

  //   if (upperCase(weightIn) === 'KG') {
  //     weightInKilograms = Number(patientWeight)
  //   } else {
  //     weightInKilograms = convertPoundToKg(Number(patientWeight))
  //   }

  //   const dosageAccordingToWeight =
  //     Number(doctorIndication) * Number(weightInKilograms)

  //   const resultInMl =
  //     (Number(dosageAccordingToWeight) * Number(concentrationInML)) /
  //     Number(concentrationInMG)

  //   const _results = getResults(resultInMl)

  //   console.log({
  //     _results,
  //     patientWeight,
  //     weightIn,
  //     weightInKilograms,
  //     resultInMl,
  //     dosageAccordingToWeight,
  //     concentrationInML,
  //     concentrationInMG,
  //   })

  //   setResults(_results)
  // }

  const calculateDosage = () => {
    let weightInKilograms

    if (upperCase(weightIn) === 'KG') {
      weightInKilograms = Number(patientWeight)
    } else {
      weightInKilograms = convertPoundToKg(Number(patientWeight))
    }

    const dosageAccordingToWeight =
      Number(doctorIndication) * Number(weightInKilograms)

    const resultInMl =
      (Number(dosageAccordingToWeight) * Number(concentrationInML)) /
      Number(concentrationInMG)

    const result = dayOrDose === 'DOSE' ? resultInMl : resultInMl / frequency

    const _results = getResults({ resultInMl: result, frequency, dayOrDose })

    setResults(_results)
  }

  return {
    weightIn,
    setWeightIn,
    patientWeight,
    setPatientWeight,
    concentrationInMG,
    setConcentrationInMG,
    concentrationInML,
    setConcentrationInML,
    doctorIndication,
    setDoctorIndication,
    results,
    calculateDosage,
    resultIn,
    setResultIn,
    dayOrDose,
    setDayOrDose,
    frequency,
    setFrequency,
    Units,
  }
}
