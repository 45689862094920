import React from 'react'
import Grid from '@mui/material/Unstable_Grid2' // Grid version 2
import { Box } from '@mui/material'
import ProductItem from 'features/sales/components/ProductItem'
import { uniqueId } from 'lodash'

export default function InvoiceProducts({
  products,
  updateProduct,
  removeProduct,
}) {
  return (
    <Box
      sx={{
        height: 'calc(100vh - 290px)',
        overflowX: 'hidden',
        overflowY: 'auto',
        paddingBottom: 2,
      }}
      className={'scroller'}
    >
      <Grid container spacing={2}>
        {products?.map((product, index) => {
          return (
            <React.Fragment key={uniqueId()}>
              <Grid xs={6}>
                <ProductItem
                  product={product}
                  updateProduct={updateProduct}
                  removeProduct={removeProduct}
                  index={index}
                />
              </Grid>
            </React.Fragment>
          )
        })}
      </Grid>
    </Box>
  )
}
