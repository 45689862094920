import React from 'react'
import useLocationsSelector from 'hooks/selectors/useLocationsSelector'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
} from '@mui/material'
import randomKey from 'utils/randomKey'

export default function LocationsDialog({
  visible = false,
  onClose,
  newLocation,
  setNewLocation,
}) {
  const { state, getData } = useLocationsSelector()

  return (
    <Dialog
      keepMounted
      open={visible}
      onClose={onClose}
      maxWidth="xs"
      fullWidth={true}
    >
      <DialogTitle>Seleccione una ubicación</DialogTitle>
      <Divider />
      <List
        sx={{ width: '100%', bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {state?.data?.map(location => {
          const selected = location.value === newLocation

          return (
            <ListItemButton
              selected={selected}
              key={randomKey()}
              onClick={() => {
                setNewLocation(location?.value)
                onClose()
              }}
            >
              <ListItemText primary={location?.location} />
            </ListItemButton>
          )
        })}
      </List>
    </Dialog>
  )
}
