import { Button, Chip } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { useKeyboardEvents } from 'hooks/useKeyboardEvents'
import { isFunction, uniqueId } from 'lodash'
import React from 'react'
import { getShortcutPrefix, isAppleDevice } from 'utils/getShortcutPrefix'

export default function ButtonWithShortcut({
  variant = 'contained',
  shortcutLabel = 'Ctrl+K',
  // shortcutBgColor = 'rgba(255,255,255,0.57)',
  shortcutBgColor = '#f7f7f7',
  shortcutTextColor = 'text.primary',
  children,
  fullWidth = true,
  keyCode = 75,
  keyLabel = 'K',
  ctrlKey = true,
  shiftKey = false,
  altKey = false,
  onClick = null,
  keyAction = null,
  tabIndex = 100,
  ...rest
}) {
  const bg = shortcutBgColor ? shortcutBgColor : 'default'

  useKeyboardEvents({
    keyCode: keyCode,
    action: isFunction(onClick) ? onClick : keyAction,
    ctrlKey: ctrlKey,
    shiftKey: shiftKey,
    altKey: altKey,
  })

  const ctrlUnicode = `${getShortcutPrefix()}`
  const shiftUnicode = '⇧'
  const altUnicode = isAppleDevice ? '⌥' : 'Alt'

  const getShortcutLabels = () => {
    const ctrlLabel = ctrlKey ? ctrlUnicode : ''
    const ShiftSymbol = shiftKey ? shiftUnicode : ''
    const altSymbol = altKey ? altUnicode : ''

    const keys = []

    if (ctrlKey) {
      keys.push(ctrlLabel)
    }

    if (shiftKey) {
      keys.push(ShiftSymbol)
    }
    if (altKey) {
      keys.push(altSymbol)
    }

    if (keyLabel) {
      keys.push(keyLabel)
    }

    // return `${ctrlLabel}${ShiftSymbol}${altSymbol}${keyLabel}`

    return keys
  }

  const shortcutLabels = getShortcutLabels()

  return (
    <Button
      variant={variant}
      fullWidth={fullWidth}
      endIcon={
        <Grid2 container spacing={0.5}>
          {shortcutLabels.map(keyLabel => {
            return (
              <React.Fragment key={uniqueId()}>
                <Grid2>
                  <Chip
                    size="small"
                    label={keyLabel}
                    sx={{
                      fontSize: '9px !important',
                      borderRadius: '5px',
                      background: bg,
                      textColor: shortcutTextColor,
                      border: `1px solid #bebebe`,
                      boxShadow: `0 2px 0 #bebebe`,
                    }}
                  />
                </Grid2>
              </React.Fragment>
            )
          })}
        </Grid2>
      }
      onClick={onClick}
      {...rest}
    >
      {children}
    </Button>
  )
}
