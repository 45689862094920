import { es } from 'date-fns/locale/es'
import { startOfWeek } from 'date-fns'
import { endOfWeek } from 'date-fns'
import { formatDate } from 'utils/formatDate'

const format = 'dd MMM yyyy'

export const getWeek = (date = new Date()) => {
  const start = startOfWeek(date, { locale: es, weekStartsOn: 1 })

  const end = endOfWeek(date, { locale: es, weekStartsOn: 1 })

  return `${formatDate(start, format)} - ${formatDate(end, format)}`
}
