import { Box } from '@mui/material'
import BaseSelector from 'components/BaseSelector'
import useLocationsSelector from 'hooks/selectors/useLocationsSelector'
import useModal from 'hooks/useModal'
import React from 'react'

export default function LocationSelector({
  size = 'small',
  variant = 'filled',
  value = {},
  setValue = () => {},
}) {
  const form = useModal()
  const { state, getData } = useLocationsSelector()

  return (
    <Box>
      <BaseSelector
        size={size}
        variant={variant}
        data={state?.data}
        getData={getData}
        formModal={form}
        inputLabel="Ubicación"
        ctaButton={{
          text: 'Nueva Ubicación',
          action: () => {},
        }}
        value={value}
        setValue={setValue}
      />
    </Box>
  )
}
