import { useEffect, useState } from 'react'
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore'
import { db } from 'config/firebase'
import { snakeCase, isEmpty } from 'lodash'
import usePharmacy from './usePharmacy'

export default function useCustomerSelector() {
  const [state, setState] = useState({
    loading: true,
    error: false,
    data: [],
  })
  const { selectedPharmacy } = usePharmacy()

  const getCustomerByName = name => {
    if (isEmpty(state?.data)) return {}

    const result = state?.data?.find(
      customer => snakeCase(customer?.name) === snakeCase(name)
    )

    if (isEmpty(result)) return {}

    return result
  }

  const getData = async () => {
    try {
      const pharmacy_ID = selectedPharmacy?.pharmacy_ID

      if (!pharmacy_ID) return

      const q = query(
        collection(db, 'customers'),
        where('pharmacy_ID', '==', pharmacy_ID),
        orderBy('created_date', 'desc')
      )

      const querySnapshot = await getDocs(q)

      if (querySnapshot?.empty) {
        setState({
          error: false,
          loading: false,
          data: [],
        })
      } else {
        const _data = []

        querySnapshot.forEach(documentSnapshot => {
          const response = documentSnapshot.data()
          const _item = {
            docID: documentSnapshot.id,
            ...response,
            label: response?.name,
            value: response?.name,
          }

          _data.push(_item)
        })

        setState({
          error: false,
          loading: false,
          data: _data,
        })
      }
    } catch (error) {
      console.error('Error al obtener la lista de clientes')
      console.error(error)
    }
  }

  useEffect(() => {
    getData()
  }, [selectedPharmacy?.pharmacy_ID])

  return { state, getData, getCustomerByName }
}
