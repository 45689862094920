import React from 'react'
import { useRefinementList } from 'react-instantsearch-core'
import ChipFilterAlgolia from 'components/ChipFilterAlgolia'

export default function PharmaceuticalForm({
  size = 'small',
  borderRadius = 1,
}) {
  const { items, refine, searchForItems } = useRefinementList({
    attribute: 'pharmaceutical_form',
    operator: 'or',
  })

  return (
    <ChipFilterAlgolia
      options={items}
      label="Forma"
      title={'Filtrar por Stock'}
      defaultIndex={0}
      onChange={payload => {
        refine(payload)
      }}
      searchForItems={searchForItems}
      borderRadius={borderRadius}
      size={size}
    />
  )
}
