import { OpenInNew, Search } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import FlexContainer from 'components/FlexContainer'
import React from 'react'
import CollapseList from 'components/CollapseList'
import QuestionIcon from '@mui/icons-material/QuestionMarkOutlined'
import CreatedIcon from '@mui/icons-material/EventOutlined'
import UseIcon from '@mui/icons-material/ScienceOutlined'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import TextGroup from 'components/TextGroup'

const activeBgColor = 'hsl(210, 100%, 96%)'
const inactiveBgColor = 'rgba(246, 247, 248, 0.4)'

const activeBorder = '1px solid hsl(210, 100%, 70%)'
const inactiveBorder = '1px solid rgba(232, 234, 238, 0.5)'

const LinkButton = ({ url, children }) => {
  return (
    <Box
      sx={theme => ({
        display: 'block',
        borderRadius: 1,
        padding: 1,
        backgroundColor: inactiveBgColor,
        border: inactiveBorder,
        mb: 2,
        textDecoration: 'none',

        '&:hover': {
          backgroundColor: activeBgColor,
          border: activeBorder,
        },
      })}
      component={'a'}
      target="_blank"
      href={url}
    >
      <FlexContainer>
        <FlexContainer justifyContent="flex-start">
          <Search color="primary" fontSize="16px" />

          <Typography
            sx={{ ml: 2, textTransform: 'none' }}
            variant="button"
            color="primary"
          >
            {children}
          </Typography>
        </FlexContainer>
        <OpenInNew color="primary" fontSize="16px" />
      </FlexContainer>
    </Box>
  )
}

export default function Detail({ productData, activityModal }) {
  const fullProductName = `${productData?.product_name} ${productData?.pharmaceutical_form} ${productData?.laboratory}`
  const fullProductNameDisplay = productData?.product_name

  return (
    <Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridGap: '16px',
          mb: 3,
        }}
      >
        <Box
          sx={theme => ({
            padding: 2,
            borderRadius: 1,
            border: `1px solid ${theme.palette.divider}`,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          })}
        >
          <Typography variant="subtitle1" align="center">
            Stock actual
          </Typography>

          <Typography variant="h3" align="center">
            {productData?.stock}
          </Typography>

          <Box sx={{ mt: 3 }}>
            <TextGroup mb={0} label="Estado" value={productData?.stockStatus} />

            <TextGroup
              mb={0}
              label="Stock Minimo"
              value={productData?.minStock}
            />
            <TextGroup
              mb={0}
              label="Stock Maximo"
              value={productData?.maxStock}
            />
          </Box>
          {/* <TextGroup label="Status" value={productData?.status} /> */}
        </Box>
        <Box
          sx={theme => ({
            padding: 2,
            borderRadius: 1,
            border: `1px solid ${theme.palette.divider}`,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          })}
        >
          <Typography variant="subtitle1" align="center">
            Ventas semanales
          </Typography>
          {/* <Typography
            variant="caption"
            align="center"
            sx={{
              display: 'block',
            }}
          >
            {`Últimos ${productData?.totalSalesLabel}`}
          </Typography> */}
          <Typography variant="h3" align="center">
            {productData?.averageSalesPerWeek}
          </Typography>
          <Box sx={{ mt: 3 }}>
            <TextGroup
              mb={0}
              label={`Total ${productData?.totalSalesLabel}`}
              value={productData?.totalSalesCount}
            />
            <TextGroup
              mb={0}
              label="Promedio al dia"
              value={productData?.averageSalesPerDay}
            />

            <TextGroup
              mb={0}
              label="Tardara en rotar"
              value={`${productData?.result} dias`}
            />
          </Box>
          {/* <TextGroup label="Ultima venta" value={productData?.lastSalePretty} />{' '} */}
        </Box>
      </Box>

      <Typography variant="subtitle1" gutterBottom>
        Detalle del producto
      </Typography>
      <Box>
        <CollapseList title={'General'} icon={InfoIcon}>
          <TextGroup
            label="Código"
            value={productData?.product_code}
            copyValue
          ></TextGroup>
          <TextGroup label="Ubicacion" value={productData?.location} />
          <TextGroup
            label="Forma farmacéutica"
            value={productData?.pharmaceutical_form}
          />
          <TextGroup label="laboratorio" value={productData?.laboratory} />
          <TextGroup
            label="Principio activo"
            value={productData?.active_principle}
          />
          <TextGroup label="Categoría" value={productData?.category} />
          <TextGroup
            label="Requiere prescripción médica"
            value={productData?.prescription_required ? 'Sí' : 'No'}
          />
          <TextGroup label="Proveedor" value={productData?.supplier_name} />
        </CollapseList>

        {/* <Divider /> */}

        <CollapseList title={'Uso y Patología'} icon={UseIcon}>
          <TextGroup
            label="Patología"
            value={productData?.pathologies}
          ></TextGroup>
          <TextGroup label="Usos" value={productData?.uses} />
        </CollapseList>
        {/* <Divider /> */}
        <CollapseList
          title={'Sobre la creación de este producto'}
          icon={CreatedIcon}
        >
          <TextGroup
            label="Creado por"
            value={productData?.createdBy?.user_name}
          ></TextGroup>

          {/* <TextGroup
          label="Fecha de creación"
          value={
            isDate(productData?.created_date?.toDate())
              ? formatDate(
                  productData?.created_date?.toDate(),
                  'dd MMMM yyyy, h:mm a'
                )
              : '-'
          }
        /> */}
        </CollapseList>
        {/* <Divider /> */}
        <CollapseList title={'Preguntas frecuentes'} icon={QuestionIcon}>
          <LinkButton
            url={`https://www.google.com.ni/search?q=que+es+${fullProductName}`}
          >{`¿Qué es ${fullProductNameDisplay}?`}</LinkButton>
          <LinkButton
            url={`https://www.google.com/search?q=${fullProductName}+para+que+sirve`}
          >{`${fullProductNameDisplay} ¿Para que sirve?`}</LinkButton>
          <LinkButton
            url={`https://www.google.com.ni/search?q=como+usar+${fullProductName}`}
          >{`¿Cómo usar ${fullProductNameDisplay}?`}</LinkButton>
          <LinkButton
            url={`https://www.google.com.ni/search?q=${fullProductName}+dosis`}
          >{`${fullProductNameDisplay} dosis`}</LinkButton>
          <LinkButton
            url={`https://www.google.com.ni/search?q=${fullProductName}+fotos`}
          >{`${fullProductNameDisplay} fotos`}</LinkButton>
          <LinkButton
            url={`https://www.google.com.ni/search?q=${fullProductName}+efectos+secundarios`}
          >{`${fullProductNameDisplay} efectos secundarios`}</LinkButton>
          <LinkButton
            url={`https://www.google.com.ni/search?q=${fullProductName}+contraindicaciones`}
          >{`${fullProductNameDisplay} contraindicaciones`}</LinkButton>
          <LinkButton
            url={`https://www.google.com.ni/search?q=${fullProductName}+alternativas`}
          >{`${fullProductNameDisplay} alternativas`}</LinkButton>
        </CollapseList>
      </Box>
    </Box>
  )
}
