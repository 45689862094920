import { useRef } from 'react'
import { isEmpty } from 'lodash'
import usePharmacy from 'hooks/usePharmacy'
import { searchProductInDB } from 'services/searchProductInDB'
import useLocalStorage from 'hooks/useLocalStorage'
import { toast } from 'sonner'

export default function useReceiveOrder() {
  const inputRef = useRef(null)
  const { selectedPharmacy, users } = usePharmacy()
  const { pharmacy_ID } = selectedPharmacy
  const [currentProduct, setCurrentProduct] = useLocalStorage(
    `receive_batch_product${pharmacy_ID}`,
    {}
  )

  const addProduct = async ({
    barcode,
    from_list = false,
    onSuccess = () => {},
    onFailure = () => {},
  }) => {
    try {
      const product_found = await searchProductInDB({
        code: barcode,
        selectedPharmacy,
      })

      const THE_PRODUCT_EXISTS = Boolean(product_found?.exists) === true
      const THE_PRODUCT_DOES_NOT_EXIST =
        Boolean(product_found?.exists) === false

      if (THE_PRODUCT_EXISTS) {
        const product = {
          product_ID: product_found?.id,
          ...product_found?.data(),
        }

        setCurrentProduct(product)

        // toast('Producto agregado')

        return { productNotFound: false }
      } else if (THE_PRODUCT_DOES_NOT_EXIST) {
        //SI el producto no existe en la BD

        toast.error('Este producto no está en su inventario.')
        onFailure()

        return { productNotFound: true }
      }

      onSuccess()
    } catch (err) {
      console.error(err)
    }
  }

  const onBarcodeScanWithScanner = ({
    productCode,
    onSuccess = () => {},
    onFailure = () => {},
  }) => {
    if (!productCode) return

    addProduct({
      barcode: productCode,
      onSuccess,
      onFailure,
    })
  }

  const addProductFromList = product => {
    if (isEmpty(product)) return

    addProduct({
      barcode: product?.product_code,
      from_list: true,
    })
  }

  return {
    onBarcodeScanWithScanner,
    addProductFromList,
    inputRef,
    currentProduct,
    setCurrentProduct,
  }
}
