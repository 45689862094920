import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@mui/material'
import React, { useEffect } from 'react'
import useProductDetail from './useProductDetail'
import { cropText } from 'utils/cropText'
import FlexContainer from 'components/FlexContainer'
import Detail from './Detail'

export default function ProductDetail({ product_ID, onClose }) {
  const { state, parseAmount } = useProductDetail({ product_ID })

  const productData = state?.data

  return (
    <Dialog
      keepMounted
      open={true}
      maxWidth="sm"
      fullWidth={true}
      onClose={onClose}
      sx={{
        '.MuiDialog-paper': {
          minHeight: '700px',
          height: '700px',
        },
      }}
    >
      <DialogTitle>
        <FlexContainer
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Typography variant="h6" color="text.primary">
            {cropText(productData?.product_name)}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {cropText(
              `${productData?.pharmaceutical_form} ${productData?.laboratory} - ${productData?.location}`
            )}
          </Typography>
        </FlexContainer>
      </DialogTitle>
      <Divider />

      <DialogContent sx={{ padding: 2 }}>
        <Detail
          {...{
            productData,
          }}
        />
      </DialogContent>
    </Dialog>
  )
}
