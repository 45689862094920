import { Box, Paper, Step, StepLabel, Stepper } from '@mui/material'
import React from 'react'
import useCreateProduct from './useAddNewProductModal'
import Form from './Form'
import ReceiveBatch from 'features/orders/components/ReceiveBatch'

const steps = [
  {
    label: 'Detalle del producto',
    value: 0,
  },
  {
    label: 'Lotes',
    value: 1,
  },
]

export default function AddNewProductModal({ onClose }) {
  const {
    errors,
    getFormProps,
    hasBarcode,
    toggleHasBarcode,
    togglePrescriptionRequired,
    prescriptionRequired,
    product,
    setProduct,
    handleSubmit,
    watch,
    setValue,
    createNewProduct,
    onNextStep,
    activeStep,
    onPreviousStep,
    resetNewProduct,
    codeAlreadyExists,
    isExistingProduct,
  } = useCreateProduct()

  const formProps = {
    hasBarcode,
    toggleHasBarcode,
    prescriptionRequired,
    togglePrescriptionRequired,
    getFormProps,
    errors,
    onNextStep,
    handleSubmit,
    watch,
    setValue,
    codeAlreadyExists,
    isExistingProduct,
  }

  const isForm = activeStep === steps[0].value

  return (
    <Box
      sx={{
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: '100%',
          borderRadius: 1,
          backgroundColor: 'background.paper',
        }}
      >
        <Paper
          sx={{
            padding: 2,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            border: 'none',
            boxShadow: `rgba(9, 11, 11, 0.15) 0px 2px 2px`,
          }}
          elevation={1}
        >
          <Stepper activeStep={activeStep}>
            {steps?.map(step => (
              <Step key={step.value}>
                <StepLabel>{step?.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Paper>

        <Box
          sx={{
            display: isForm ? 'block' : 'none',
          }}
        >
          <Form {...formProps} />
        </Box>

        <Box
          sx={{
            display: !isForm ? 'block' : 'none',
          }}
        >
          <ReceiveBatch
            contextOfUse="NEW_PRODUCT"
            isNewProduct={!isExistingProduct}
            isExistingProduct={isExistingProduct}
            inputRef={null}
            setCurrentProduct={setProduct}
            currentProduct={product}
            createNewProduct={createNewProduct}
            resetNewProduct={resetNewProduct}
            onPrevious={onPreviousStep}
          />
        </Box>
      </Box>
    </Box>
  )
}
