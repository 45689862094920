import { isEmpty } from 'lodash'
import { cropText } from './cropText'

export const getDescription = ({ data, property = 'name' }) => {
  if (isEmpty(data)) return ''

  const result = data?.map(item => item[property])

  const uniqueValues = [...new Set(result)]

  return uniqueValues?.toString()
}

export const getShortDescription = ({
  data,
  property = 'name',
  itemsToShow = 4,
}) => {
  if (isEmpty(data)) return ''

  const hasMoreProducts = data.length > itemsToShow

  const dataInput = hasMoreProducts ? data.slice(0, itemsToShow) : data

  const countRemainingProducts = data?.length - dataInput?.length

  const hasCountRemainingProducts = countRemainingProducts >= 1

  const result = dataInput?.map(item => cropText(item[property]))

  const uniqueValues = [...new Set(result)]

  const displayProduct = uniqueValues?.toString()

  const remainingProductsLabel = `+${countRemainingProducts} ${
    countRemainingProducts === 1 ? 'producto' : 'productos'
  }`

  return `${displayProduct} ${
    hasCountRemainingProducts ? remainingProductsLabel : ''
  }`
}
