import { useForm } from 'react-hook-form'
import { useEffect } from 'react'
import usePharmacy from 'hooks/usePharmacy'
import { auth } from 'config/firebase'
import { toast } from 'sonner'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import { CREATE_INVOICE } from 'config/routes/paths'

const INVALID_USER = 'auth/user-not-found'
const INVALID_PASSWORD = 'auth/wrong-password'

export default function useLogin() {
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: { login_email: '', login_password: '' },
  })

  const navigate = useNavigate()

  const goHome = () => {
    navigate(CREATE_INVOICE)
  }

  const { prefilledEmail } = usePharmacy()

  const signIn = ({ email, password }) => {
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        goHome()
      })
      .catch(error => {
        const errorCode = error.code

        const codes = [INVALID_USER, INVALID_PASSWORD]

        if (codes.includes(errorCode)) {
          toast.error('El Correo o la contraseña son incorrectos')
        }

        console.error(error)
      })
  }

  const onSubmit = data => {
    signIn({ email: data.login_email, password: data.login_password })
  }

  const getFormProps = ({ name, rules = { required: true } }) => {
    return {
      name,
      control,
      rules,
    }
  }

  const goToInitialView = () => {}

  useEffect(() => {
    if (!prefilledEmail) return

    setValue('login_email', prefilledEmail)
  }, [prefilledEmail])

  return {
    handleSubmit,
    onSubmit,
    getFormProps,
    setValue,
    watch,
    goToInitialView,
    prefilledEmail,
    control,
    errors,
  }
}
