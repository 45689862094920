// import moment from 'moment'

import { format } from 'date-fns'
import { es } from 'date-fns/locale/es'

// moment.locale('es')

// export const formatDate = (date, stringFormat) => {
//   return moment(date).format(stringFormat)
// }

export const formatDate = (date, stringFormat) => {
  return format(date, stringFormat, {
    locale: es,
  })
}
