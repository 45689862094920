export const InvoiceStatements = {
  PAID_IN_CASH: {
    label: 'Pagado al contado',
    value: 'PAID_IN_CASH',
  },
  CREDIT_PAID: {
    label: 'Pagado al credito',
    value: 'CREDIT_PAID',
  },
  PROFORM: {
    label: 'Proforma',
    value: 'PROFORM',
  },
  CANCELLED: {
    label: 'Anulado',
    value: 'CANCELLED',
  },
  ACTIVE_CREDIT: {
    label: 'Crédito activo',
    value: 'ACTIVE_CREDIT',
  },
}
