import React from 'react'
import { Box } from '@mui/material'

export default function Container({
  children,
  bgColor = '#F5F5F5',
  padding = 0,
  maxHeight = 'none',
  minHeight = '100vh',
  overflow = 'auto',
  hideScrollbar = false,
}) {
  const scrollStyles = hideScrollbar
    ? {
        '-ms-overflow-style': 'none' /* for Internet Explorer, Edge */,
        'scrollbar-width': 'none' /* for Firefox */,
        'overflow-y': 'scroll',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }
    : {}

  return (
    <Box
      sx={{
        maxWidthwidth: '100vw',
        minHeight: minHeight,
        padding: padding,
        backgroundColor: bgColor,
        boxSizing: 'border-box',
        maxHeight,
        overflow,
        ...scrollStyles,
      }}
    >
      {children}
    </Box>
  )
}
