import { createContext, useEffect, useMemo, useState } from 'react'
import { isEmpty } from 'lodash'
import { auth } from 'config/firebase'
import useLocalStorage from 'hooks/useLocalStorage'
import { isFunction } from 'lodash'
import { signOut as signOutFirebase } from 'firebase/auth'

export const PharmacyContext = createContext()

export function PharmacyContextProvider({ children }) {
  const [pharmacies, setPharmacies] = useLocalStorage('pharmacies', [])
  const [users, setUsers] = useLocalStorage('users', [])
  const [prefilledEmail, setPrefilledEmail] = useLocalStorage(
    'prefilled_email',
    ''
  )

  const [selectedPharmacy, setSelectedPharmacy] = useState({})
  const [dateFormat, setDateFormat] = useLocalStorage('dateFormat', {})
  const [primaryCurrency, setPrimaryCurrency] = useLocalStorage(
    'primaryCurrency',
    {}
  )
  const [secondaryCurrency, setSecondaryCurrency] = useLocalStorage(
    'secondaryCurrency',
    {}
  )

  const updatePharmacyList = _pharmacies => {
    if (isEmpty(_pharmacies)) {
      setPharmacies([])
    } else {
      setPharmacies(_pharmacies)
    }
  }

  const updateUserList = _users => {
    if (isEmpty(_users)) {
      setUsers([])
    } else {
      setUsers(_users)
    }
  }

  const changePharmacy = _pharmacy => {
    if (isEmpty(_pharmacy)) {
      setSelectedPharmacy({})
    } else {
      setSelectedPharmacy(_pharmacy)
    }
  }

  const changeDateFormat = _format => {
    if (isEmpty(_format)) return

    setDateFormat(_format)
  }

  const changePrimaryCurrency = _currency => {
    if (isEmpty(_currency)) return

    setPrimaryCurrency(_currency)
  }

  const changeSecondaryCurrency = _currency => {
    if (isEmpty(_currency)) return

    setSecondaryCurrency(_currency)
  }

  const signOut = ({ prefilledEmail = '', onSuscess = () => {} }) => {
    try {
      setPrefilledEmail(prefilledEmail)

      signOutFirebase(auth).then(() => {
        setPharmacies([])
        setUsers([])
        setSelectedPharmacy({})
        setDateFormat({})
        setPrimaryCurrency({})
        setSecondaryCurrency({})

        if (isFunction(onSuscess)) {
          onSuscess()
        }
      })
    } catch (err) {
      console.error(err)
    }
  }

  const value = useMemo(() => {
    return {
      pharmacies,
      selectedPharmacy,
      dateFormat,
      primaryCurrency,
      secondaryCurrency,
      updatePharmacyList,
      changePharmacy,
      changeDateFormat,
      changePrimaryCurrency,
      changeSecondaryCurrency,
      updateUserList,
      users,
      prefilledEmail,
      setPrefilledEmail,
      signOut,
    }
  }, [
    pharmacies,
    selectedPharmacy,
    dateFormat,
    primaryCurrency,
    secondaryCurrency,
    updatePharmacyList,
    changePharmacy,
    changeDateFormat,
    changePrimaryCurrency,
    changeSecondaryCurrency,
    updateUserList,
    users,
    prefilledEmail,
    setPrefilledEmail,
    signOut,
  ])

  return (
    <PharmacyContext.Provider value={value}>
      {children}
    </PharmacyContext.Provider>
  )
}
