import React from 'react'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckIcon from '@mui/icons-material/Check'
import WarningIcon from '@mui/icons-material/WarningAmberOutlined'
import ErrorIcon from '@mui/icons-material/Error'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'

import { differenceInDays, differenceInMonths, isBefore } from 'date-fns'
import { Chip } from '@mui/material'
import { amber, red, yellow } from '@mui/material/colors'
import WarningFilledIcon from '@mui/icons-material/Warning'
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'

const ONE_WEEK = 7
const ONE_MONTH = 30
const TWO_MONTHS = 60
const THREE_MONTHS = 90

const FOUR_MONTHS = 120

export const ExpirationStatus = {
  EXPIRED: {
    text: 'Expirado',
    icon: ErrorIcon,
    iconColor: 'error',
    textColor: 'error',
  },
  NEXT_MONTH: {
    text: 'Pocas unidades',
    icon: WarningIcon,
    iconColor: 'warning',
    textColor: 'text.primary',
  },
  NEXT_2_MONTHS: {
    text: 'Stock sano',
    icon: CheckIcon,
    iconColor: 'success',
    textColor: 'text.primary',
  },
  NEXT_3_MONTHS: {
    text: 'Stock excesivo',
    icon: InfoIcon,
    iconColor: 'info',
    textColor: 'text.primary',
  },
}

const chipProps = {
  size: 'small',
  variant: 'outlined',
}

const getExpirarionStatus = ({ expirationDate, label }) => {
  const today = new Date()

  const daysUntilExpiration = differenceInDays(expirationDate, today)

  const isExpired = daysUntilExpiration <= ONE_WEEK

  if (isExpired) {
    return (
      <Chip
        label={label}
        icon={<CrisisAlertIcon />}
        color="error"
        size="small"
        variant="filled"
        sx={{
          backgroundColor: red[500],
        }}
      />
    )
  } else if (daysUntilExpiration <= TWO_MONTHS) {
    return (
      <Chip
        label={label}
        icon={<ErrorIcon color="error" />}
        color="default"
        {...chipProps}
        variant="filled"
        sx={{
          backgroundColor: red[100],
        }}
      />
    )
  } else if (
    daysUntilExpiration > TWO_MONTHS &&
    daysUntilExpiration <= THREE_MONTHS
  ) {
    return (
      <Chip
        label={label}
        icon={<WarningFilledIcon color="warning" />}
        color="default"
        {...chipProps}
        variant="filled"
        sx={{
          backgroundColor: amber[100],
        }}
      />
    )
  } else if (
    daysUntilExpiration > THREE_MONTHS &&
    daysUntilExpiration <= FOUR_MONTHS
  ) {
    return (
      <Chip
        label={label}
        icon={<WarningIcon color="warning" />}
        color="default"
        {...chipProps}
        variant="filled"
        sx={{
          backgroundColor: amber[50],
        }}
      />
    )
  } else {
    return (
      <Chip
        icon={<EventAvailableIcon />}
        label={label}
        color="default"
        variant="filled"
        size="small"
      />
    )
  }
}

export default function ExpirationChip({ label, expirationDate }) {
  const content = getExpirarionStatus({ expirationDate, label })

  return content
}
