import { isNumber } from 'lodash'
import { numberIsPositive } from 'hooks/useParsers'

export const numberIsValid = value => {
  if (!isNumber(value)) return false

  if (!numberIsPositive(value)) return false

  return true
}
