import * as React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import CssBaseline from '@mui/material/CssBaseline'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import MailIcon from '@mui/icons-material/Mail'
import MenuIcon from '@mui/icons-material/Menu'
import { ThemeProvider, createTheme, alpha } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'
import SearchIcon from '@mui/icons-material/Search'
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCartOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'
import AccountMenu from 'components/AccountMenu'
import FlexContainer from 'components/FlexContainer'
import PharmacySelector from 'components/PharmacySelector'
import Logo from 'assets/Logo'
//Icons
import SalesIcon from '@mui/icons-material/PointOfSale'
import ProductsIcon from '@mui/icons-material/BookOutlined'
import OrdersIcon from '@mui/icons-material/LocalShippingOutlined'
import HomeIcon from '@mui/icons-material/HomeOutlined'
import ReturnIcons from '@mui/icons-material/AssignmentReturnOutlined'
import CustomersIcon from '@mui/icons-material/PeopleAltOutlined'
import LocationsIcon from '@mui/icons-material/MyLocationOutlined'
import FormsIcon from '@mui/icons-material/VaccinesOutlined'
import LaboratoriesIcon from '@mui/icons-material/BiotechOutlined'
import SuppliersIcon from '@mui/icons-material/AssignmentIndOutlined'

import {
  ADMIN,
  ALL_PRODUCTS,
  CREATE_INVOICE,
  LOGOUT,
  RECEIVE_ORDER,
} from 'config/routes/paths'
import { ListSubheader } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import HomeIconActive from '@mui/icons-material/Home'
import BookIconActive from '@mui/icons-material/Book'
import OrdersIconActive from '@mui/icons-material/LocalShipping'
import { GlobalSearchButton } from 'components/GlobalSearchButton'

const drawerWidth = 240

const openedMixin = theme => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

const options = [
  {
    label: 'Inicio',
    value: 'HOME',
    icon: HomeIcon,
    activeIcon: HomeIconActive,
    path: ADMIN,
    group: '',
  },
  {
    label: 'Ventas',
    value: 'SALES',
    icon: SalesIcon,
    activeIcon: SalesIcon,
    path: CREATE_INVOICE,
    group: '',
  },
  {
    label: 'Productos',
    value: 'PRODUCTS',
    icon: ProductsIcon,
    activeIcon: BookIconActive,
    path: ALL_PRODUCTS,
    group: '',
  },
  {
    label: 'Pedidos',
    value: 'ORDERS',
    icon: OrdersIcon,
    activeIcon: OrdersIconActive,
    path: RECEIVE_ORDER,
    group: '',
  },
]

const catalogOptions = [
  {
    label: 'Clientes',
    value: 'CUSTOMERS',
    icon: CustomersIcon,
    path: ALL_PRODUCTS,
    group: '',
  },
  {
    label: 'Ubicaciones',
    value: 'CUSTOMERS',
    icon: LocationsIcon,
    path: ALL_PRODUCTS,
    group: '',
  },
  {
    label: 'Formas',
    value: 'FORMS',
    icon: FormsIcon,
    path: ALL_PRODUCTS,
    group: '',
  },
  {
    label: 'Laboratorios',
    value: 'LABORATORIES',
    icon: LaboratoriesIcon,
    path: ALL_PRODUCTS,
    group: '',
  },
  {
    label: 'Proveedores',
    value: 'SUPPLIERS',
    icon: SuppliersIcon,
    path: ALL_PRODUCTS,
    group: '',
  },
]

export default function Layout({ children }) {
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)
  let location = useLocation()
  const navigate = useNavigate()

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#1976d2',
      },
    },
  })

  const activePath = location?.pathname

  const goTo = path => {
    navigate(path)
  }

  const signOut = () => {
    navigate(LOGOUT)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      {/* <ThemeProvider theme={darkTheme}> */}
      <AppBar
        position="fixed"
        open={open}
        // variant="outlined"
        sx={{
          background: '#121212',
        }}
      >
        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          {/* <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ display: { xs: 'none', sm: 'block' } }}
            >
              Farmagestor
            </Typography> */}
          <ThemeProvider theme={darkTheme}>
            <PharmacySelector />
          </ThemeProvider>

          <GlobalSearchButton />
          <ThemeProvider theme={darkTheme}>
            <Box sx={{ ml: 'auto' }}>
              <FlexContainer>
                {/* <PharmacySelector /> */}
                <Box sx={{ mr: 2 }}>
                  <IconButton
                    sx={{ mx: 1 }}
                    onClick={() => {
                      goTo(RECEIVE_ORDER)
                    }}
                  >
                    <ShoppingCartIcon color={theme.palette.text.primary} />
                  </IconButton>
                  {/* <IconButton sx={{ mx: 1 }} color="text.primary">
                    <NotificationsNoneOutlinedIcon />
                  </IconButton>

                  <IconButton sx={{ mx: 1 }} color={theme.palette.text.primary}>
                    <SettingsOutlinedIcon />
                  </IconButton> */}
                  <IconButton
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: 1,
                      ':hover': {
                        backgroundColor: theme.palette.primary.dark,
                      },
                    }}
                    onClick={() => {
                      goTo(CREATE_INVOICE)
                    }}
                  >
                    <PointOfSaleIcon color="#fff" />
                  </IconButton>
                </Box>

                <AccountMenu />
              </FlexContainer>
            </Box>
          </ThemeProvider>
        </Toolbar>
      </AppBar>
      {/* </ThemeProvider> */}

      <Drawer variant="permanent" open={open}>
        <DrawerHeader
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <FlexContainer>
            <Logo />
            <Typography
              variant="subtitle1"
              noWrap
              component="div"
              sx={{ display: { xs: 'none', sm: 'block' } }}
            >
              Farmagestor
            </Typography>
          </FlexContainer>

          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        {open ? <Divider /> : null}
        <List>
          {options?.map((item, index) => {
            const selected = item.path === activePath

            const Icon = selected ? item.activeIcon : item.icon

            return (
              <ListItem
                key={item.value}
                disablePadding
                sx={{ display: 'block' }}
                onClick={() => {
                  goTo(item?.path)
                }}
              >
                <ListItemButton
                  selected={selected}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <Icon color={selected ? 'primary' : 'action'} />
                  </ListItemIcon>
                  <ListItemText
                    primary={item.label}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            )
          })}
        </List>
        {/* <Divider /> */}
        {/* <List>
          {open ? <ListSubheader>Catalogos</ListSubheader> : null}
          {catalogOptions?.map(item => {
            const Icon = item.icon

            return (
              <ListItem
                key={item.value}
                disablePadding
                sx={{ display: 'block' }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <Icon />
                  </ListItemIcon>
                  <ListItemText
                    primary={item.label}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            )
          })}
        </List> */}
      </Drawer>
      <Box
        component="main"
        sx={{
          boxSizing: 'border-box',
          p: 2,
          width: open ? 'calc(100vw - 240px)' : 'calc(100vw - 65px)',
        }}
      >
        <Box
          sx={{
            height: '48px',
            width: '100%',
          }}
        ></Box>
        {children}
      </Box>
    </Box>
  )
}
