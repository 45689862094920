import { isNumber } from 'lodash'
import usePharmacy from './usePharmacy'
import { floor } from 'lodash'
import { numberIsValid } from 'utils/numbers/numberIsValid'

const Currencies = {
  primary: 'primaryCurrency',
  secondary: 'secondaryCurrency',
}

const integerWithCommas = n => {
  const integer = parseInt(n)
  return integer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const numberIsPositive = number => {
  if (!isNumber(number)) return true

  if (number >= 0) return true

  return false
}

const numberWithCommas = (x, withDecimal = false) => {
  const fixed = floor(x, 2)

  let parts = fixed.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  const r = parts.join('.')
  // const res = r;

  return r
}

export default function useParsers() {
  const pharmacyContext = usePharmacy()

  const parseAmount = ({
    value,
    currency = 'primary',
    showSimbol = true,
    showCode = true,
    withDecimal = false,
    validateNumber = true,
  }) => {
    if (validateNumber && !numberIsValid(value)) return 0

    const currencyToUse = Currencies[currency]
    const { simbol, code } = pharmacyContext[currencyToUse]

    const _options = {
      prefix: showSimbol ? simbol || '' : '',
      sufix: showCode ? code : '',
      withDecimal,
    }

    const newPrefix = _options?.prefix
    const newSufix = _options?.sufix

    const sign = numberIsPositive(value) ? '' : '-'

    const absoluteValue = Math.abs(value)

    const formattedValue = numberWithCommas(absoluteValue, withDecimal)

    return `${sign}${newPrefix}${formattedValue} ${newSufix}`
  }

  return { parseAmount, numberIsPositive }
}
