import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { LOGIN } from 'config/routes/paths.js'
import usePharmacy from 'hooks/usePharmacy'
import { isFunction } from 'lodash'

export default function Logout() {
  const { signOut } = usePharmacy()
  const navigate = useNavigate()

  const goToLogin = () => {
    navigate(LOGIN)
  }

  useEffect(() => {
    if (isFunction(signOut)) {
      signOut({ onSuscess: goToLogin })
    }
  }, [signOut])

  return null
}
