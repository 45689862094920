import { format, getDate, getMonth, getTime, getYear, toDate } from 'date-fns'

export const getInitialDate = (date = new Date()) => {
  return {
    dateString: format(date, 'yyyy-MM-dd'),
    day: getDate(date),
    month: getMonth(date),
    timestamp: getTime(date),
    year: getYear(date),
  }
}
