import algoliasearch from 'algoliasearch'
import { useRef, useState } from 'react'
import usePharmacy from 'hooks/usePharmacy'
import aa from 'search-insights'

const client = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_SEARCH_API_KEY
)

aa('init', {
  appId: process.env.REACT_APP_ALGOLIA_APP_ID,
  apiKey: process.env.REACT_APP_SEARCH_API_KEY,
})

export default function useProductsList() {
  const listRef = useRef(null)
  const { selectedPharmacy } = usePharmacy()

  const [isModalOpen, setModalOpen] = useState(false)

  function scrollToTop() {
    listRef.current?.scrollToOffset({ animated: false, offset: 0 })
  }

  const REACT_APP_ALGOLIA_INDEX = process.env.REACT_APP_ALGOLIA_INDEX

  return {
    client,
    REACT_APP_ALGOLIA_INDEX,
    listRef,
    isModalOpen,
    setModalOpen,
    scrollToTop,
    pharmacy_ID: selectedPharmacy?.pharmacy_ID,
  }
}
