import React, { useState } from 'react'
import { ScanInput } from 'components/ScanInput'
import ReceiveBatch from 'features/orders/components/ReceiveBatch'
import Container from 'components/Container'
import Wrapper from 'components/Wrapper'
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material'
import useReceiveOrder from './useReceiveOrder'
import { Toaster } from 'sonner'
import MenuAppBar from 'components/Appbar'
import AddNewProductModal from 'features/products/components/AddNewProductModal'
import { Add } from '@mui/icons-material'
import ExistingProductIcon from '@mui/icons-material/ManageSearch'
import { Helmet } from 'react-helmet'

const blockSpace = 2

const inputSize = 'medium'
const iconSize = 'small'
const iconStyles = {
  mr: 1,
}

export default function ReceiveOrder() {
  const {
    onBarcodeScanWithScanner,
    addProductFromList,
    inputRef,
    currentProduct,
    setCurrentProduct,
  } = useReceiveOrder()

  const [productType, setProductType] = useState('NEW_PRODUCT')

  return (
    <Container>
      <MenuAppBar />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Recibir pedido</title>
      </Helmet>
      <Wrapper>
        <Box
          sx={{
            maxWidth: '700px',
            margin: '0 auto',
          }}
        >
          <ToggleButtonGroup
            fullWidth
            size={inputSize}
            color="primary"
            value={productType}
            exclusive
            onChange={(_, value) => {
              if (value) {
                setProductType(value)
              }
            }}
            aria-label="Platform"
            tabIndex={100}
            sx={{
              mb: blockSpace,
            }}
          >
            <ToggleButton value="NEW_PRODUCT">
              <Add fontSize={iconSize} sx={iconStyles} /> Añadir un nuevo
              producto
            </ToggleButton>
            <ToggleButton value="EXISTING_PRODUCT">
              <ExistingProductIcon fontSize={iconSize} sx={iconStyles} />{' '}
              Recibir lotes
            </ToggleButton>
          </ToggleButtonGroup>

          {productType === 'EXISTING_PRODUCT' ? (
            <ScanInput
              placeholder="Escanear producto para recibir lote (Ctrl+E)"
              action={({ productCode, onFailure }) => {
                onBarcodeScanWithScanner({ productCode, onFailure })
              }}
              onSelect={product => {
                addProductFromList(product)
              }}
              inputRef={inputRef}
            />
          ) : null}

          {productType === 'EXISTING_PRODUCT' ? (
            <ReceiveBatch
              setCurrentProduct={setCurrentProduct}
              inputRef={inputRef}
              currentProduct={currentProduct}
            />
          ) : (
            <AddNewProductModal />
          )}
        </Box>
      </Wrapper>

      <Toaster richColors position="top-right" />
    </Container>
  )
}
