import { isDate, isEmpty, isFunction, maxBy, sumBy } from 'lodash'
import { addDoc, collection } from 'firebase/firestore'
import { auth, db } from 'config/firebase'
import { getProductRef } from 'services/getProductRef'
import usePharmacy from 'hooks/usePharmacy'
import { getTime } from 'date-fns'
import { TypesOftransactions } from 'const/TypesOftransactions'
import { toast } from 'sonner'
import { formatDate } from 'utils/formatDate'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import { increaseProductStock } from 'services/inventory/increaseProductStock'
import { useEffect, useState } from 'react'
import useModal from 'hooks/useModal'

const batchInitialState = {
  batch_code: '',
  sale_price: '',
  expiration_month: '',
  expiration_year: '',
  expiration_date: '',
  cost: '',
  profit: '',
  product_code: '',
  stock: 1,
  has_an_expiration_date: true,
}

export default function useReceiveBatch({
  inputRef,
  product,
  setProduct,
  resetNewProduct,
  contextOfUse,
  isNewProduct,
}) {
  const { selectedPharmacy } = usePharmacy()
  const [loading, setLoading] = useState(false)
  const [newPrice, setNewPrice] = useState(product?.current_price)
  const [newLocation, setNewLocation] = useState(product?.location)
  const [automaticPrice, setAutomaticPrice] = useState(true)

  const changePriceModal = useModal()
  const changeLocationModal = useModal()

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      batch: [{ ...batchInitialState, sale_price: 0 }],
    },
  })

  const { fields, append, remove } = useFieldArray({ control, name: 'batch' })
  const values = useWatch({
    name: 'batch',
    control,
  })

  const toggleAutomaticPrice = event => {
    setAutomaticPrice(event.target.checked)
  }

  const formProps = {
    fields,
    control,
    handleSubmit,
    setValue,
    watch,
    errors,
    values,
  }

  const resetStates = () => {
    reset({
      batch: [{ ...batchInitialState, sale_price: 0 }],
    })

    setProduct({})
    setLoading(false)

    setAutomaticPrice(true)
    setNewPrice(0)
    setNewLocation('')
  }

  const addNewBatch = () => {
    append({ ...batchInitialState, sale_price: product?.current_price })
  }

  const updateProductActivity = async ({ batches }) => {
    try {
      const product_ID = product?.product_ID
      const user = auth.currentUser

      const user_ID = user?.uid

      const date = new Date()

      const prevStock = product?.stock || 0
      const qtyReceived = sumBy(batches, 'stock')

      const newStock = prevStock + qtyReceived

      //Registrar modificacion de stock en el log de productos
      return await addDoc(collection(db, 'batches_received'), {
        initial_batch: false,
        pharmacy_ID: selectedPharmacy?.pharmacy_ID,
        created_by: user_ID,
        affected_products: [product_ID],
        created_date: date,
        time: getTime(date),
        products: [
          {
            product_ID: product_ID,
            qty: qtyReceived,
            prev_stock: prevStock,
            new_stock: newStock,
          },
        ],
      })
    } catch (e) {
      console.error(e)
      console.error('Error en: updateProductActivity')
      setLoading(false)
    }
  }

  const saveBatchesToExistingProduct = async ({ batches }) => {
    try {
      const pharmacy_ID = selectedPharmacy?.pharmacy_ID
      const product_ID = product?.product_ID
      const unitsReceived = sumBy(batches, 'stock')
      const product_ref = getProductRef(product_ID)

      //Guardar los lotes
      for await (let batch of batches) {
        const productCode = product?.product_code
        const productName = product?.product_name

        const hasExpiration = batch?.has_an_expiration_date

        const expirationMonth = hasExpiration
          ? formatDate(batch?.expiration_date, 'MMMM')
          : ''
        const expirationYear = hasExpiration
          ? formatDate(batch?.expiration_date, 'yyyy')
          : ''

        const expirationDate = hasExpiration ? batch?.expiration_date : ''

        const batchToAdd = {
          ...batch,
          expiration_month: expirationMonth,
          expiration_year: expirationYear,
          expiration_date: expirationDate,
          pharmacy_ID,
          product_ID: product_ID,
          productRef: product_ref,
          percentage_of_profit: 30,
          product_code: productCode,
          product_name: productName,
        }

        //Guardar lotes
        await addDoc(collection(db, 'batches'), batchToAdd)
      }

      //Actualizar el inventario de cada producto
      await increaseProductStock({
        product_ref,
        pharmacy_ID,
        increase: unitsReceived,
        sourcing_time: 2,
        transaction_type: TypesOftransactions.ORDER,
        current_price: Number(newPrice),
        new_location: newLocation,
      })
    } catch (err) {
      console.error(err)

      setLoading(false)
    }
  }

  const addBatch = async ({ batches }) => {
    try {
      await saveBatchesToExistingProduct({
        batches,
      })

      await updateProductActivity({ batches })

      if (contextOfUse === 'NEW_PRODUCT') {
        resetStates()

        resetNewProduct()
      } else {
        if (isFunction(inputRef?.current?.focus)) {
          inputRef?.current?.focus()
        }

        resetStates()
      }
    } catch (err) {
      console.error(err)

      setLoading(false)
    }
  }

  const onSubmit = data => {
    const listOfBatches = data?.batch

    const invalidDates = listOfBatches?.some(batch => {
      return batch?.has_an_expiration_date && !isDate(batch?.expiration_date)
    })

    const invalidBatchCodes = listOfBatches?.some(
      batch => !Boolean(batch?.batch_code)
    )

    if (invalidBatchCodes) {
      toast.error('Especificar el codigo de los lotes.')
    } else if (invalidDates) {
      toast.error('Especificar la fecha de vencimiento de los lotes.')
    } else {
      setLoading(true)

      addBatch({ batches: listOfBatches })

      if (contextOfUse === 'NEW_PRODUCT' && isNewProduct) {
        toast.success('Nuevo producto añadido con éxito')
      } else {
        toast.success('Lotes agregados')
      }
    }
  }

  const removeBatchItem = index => {
    if (fields.length === 1) {
      toast.error('Necesita recibir al menos un lote')
    } else {
      remove(index)
    }
  }

  useEffect(() => {
    if (isEmpty(product)) return

    setValue(`batch.0.sale_price`, product?.current_price)
    setNewLocation(product?.location)
  }, [product])

  //Actalizar el label del precio del producto
  useEffect(() => {
    if (isEmpty(product)) {
      setNewPrice(0)
    } else if (automaticPrice) {
      const maxBatch = maxBy(values, 'sale_price')

      const priceOfTheMostExpensiveBatch = Number(maxBatch?.sale_price)

      setNewPrice(priceOfTheMostExpensiveBatch)
    }
  }, [automaticPrice, product, values])

  useEffect(() => {
    setAutomaticPrice(true)
  }, [product])

  return {
    addNewBatch,
    onSubmit,
    formProps,
    loading,
    removeBatchItem,
    newPrice,
    setNewPrice,
    automaticPrice,
    setAutomaticPrice,
    toggleAutomaticPrice,
    newLocation,
    setNewLocation,
    changePriceModal,
    changeLocationModal,
  }
}
