import React, { useState } from 'react'
import {
  Box,
  Chip,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from '@mui/material'
import { getShortcutPrefix } from 'utils/getShortcutPrefix'
import { useKeyboardEvents } from 'hooks/useKeyboardEvents'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { isFunction, uniqueId } from 'lodash'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import SearchIcon from '@mui/icons-material/Search'
import useModal from 'hooks/useModal'
import SearchProductModal from 'components/SearchProductModal'
import { createPortal } from 'react-dom'
import { styled, useTheme } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'
import { ThemeProvider, createTheme, alpha } from '@mui/material/styles'
import ProductDetail from 'features/products/components/ProductDetail'

const EnterKey = 13
const TabKey = 9

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}))

const keyStyles = {
  fontSize: '9px !important',
  borderRadius: '5px',
  background: '#f7f7f7',
  color: 'rgba(0,0,0,0.87)',
  border: `1px solid #bebebe`,
  boxShadow: `0 2px 0 #bebebe`,
}

export function GlobalSearchButton({
  action = () => {},
  onSelect = () => {},
  inputRef = null,
}) {
  const [value, setValue] = useState('')
  const modal = useModal()
  const productDetail = useModal()

  const resetValue = () => {
    setValue('')
  }

  const focus = () => {
    inputRef?.current?.focus()
    resetValue()
  }

  useKeyboardEvents({
    keyCode: 66,
    action: modal.open,
    ctrlKey: true,
    shiftKey: false,
    altKey: false,
  })

  const ctrlLabel = getShortcutPrefix()

  return (
    <Box>
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          onClick={modal.open}
          readOnly
          placeholder="Buscar producto"
          inputProps={{ 'aria-label': 'search' }}
        />
        <Grid2 container spacing={0.5}>
          <Grid2>
            <Chip size="small" label={ctrlLabel} sx={keyStyles} />
          </Grid2>
          <Grid2>
            <Chip size="small" label={'B'} sx={keyStyles} />
          </Grid2>
        </Grid2>
      </Search>

      {modal.visible
        ? createPortal(
            <SearchProductModal
              initialKeyword={modal?.data?.initialKeyword || ''}
              onClose={modal.close}
              onSelect={({ product, onSuccess }) => {
                productDetail.open({
                  data: { product_ID: product?.product_code },
                })

                if (isFunction(onSuccess)) {
                  onSuccess()
                }
              }}
            />,
            document.body
          )
        : null}

      {productDetail.visible ? (
        <ProductDetail
          product_ID={productDetail?.data?.product_ID}
          onClose={productDetail.close}
        />
      ) : null}
    </Box>
  )
}
