import { useTheme } from '@emotion/react'
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from '@mui/material'
import Container from 'components/Container'
import Wrapper from 'components/Wrapper'
import React from 'react'
import { Controller } from 'react-hook-form'
import { Toaster } from 'sonner'
import useLogin from './useLogin'

export default function Login() {
  const theme = useTheme()
  const { getFormProps, handleSubmit, onSubmit, errors } = useLogin()

  return (
    <Container>
      <Wrapper>
        <Toaster />
        <Card
          sx={{
            width: '300px',
            margin: 'auto',
          }}
        >
          <CardContent>
            <Typography variant="h6" align="center">
              Iniciar sesión
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                {...getFormProps({ name: 'login_email' })}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    sx={{ mt: 3, mb: 1 }}
                    size="small"
                    variant="filled"
                    type="email"
                    label="Dirección de correo electrónico"
                    error={errors['login_email']}
                    helperText={errors['login_email'] ? 'Correo requerido' : ''}
                  />
                )}
              />

              <Controller
                {...getFormProps({ name: 'login_password' })}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    sx={{ mb: 3 }}
                    size="small"
                    variant="filled"
                    label="Contraseña"
                    type="password"
                    error={errors['login_password']}
                    helperText={
                      errors['login_password'] ? 'Contraseña requerida' : ''
                    }
                  />
                )}
              />
              <Button type="submit" variant="contained" size="large" fullWidth>
                Iniciar sesion
              </Button>
            </form>
          </CardContent>
        </Card>
      </Wrapper>
    </Container>
  )
}
