import React, { Fragment } from 'react'
import {
  getHighlightedParts,
  getPropertyByPath,
} from 'instantsearch.js/es/lib/utils'
import { cropText } from 'utils/cropText'
import { Typography } from '@mui/material'
import { useTheme } from '@mui/material'

function HighlightPart({ children, isHighlighted }) {
  return (
    <Typography
      variant="body1"
      sx={isHighlighted ? styles.highlighted : styles.nonHighlighted}
    >
      {children}
    </Typography>
  )
}

export function Highlight({ hit, attribute, separator = ', ' }) {
  const { value: attributeValue = '' } =
    getPropertyByPath(hit._highlightResult, attribute) || {}
  const parts = getHighlightedParts(attributeValue)

  return (
    <>
      {parts.map((part, partIndex) => {
        if (Array.isArray(part)) {
          const isLastPart = partIndex === parts.length - 1

          return (
            <Fragment key={partIndex}>
              {part.map((subPart, subPartIndex) => (
                <HighlightPart
                  key={subPartIndex}
                  isHighlighted={subPart.isHighlighted}
                >
                  {subPart.value}
                </HighlightPart>
              ))}

              {!isLastPart && separator}
            </Fragment>
          )
        }

        return (
          <HighlightPart key={partIndex} isHighlighted={part.isHighlighted}>
            {part.value}
          </HighlightPart>
        )
      })}
    </>
  )
}

const styles = {
  highlighted: {
    // fontWeight: 'bold',
    // backgroundColor: '#f5df4d',
    // color: '#6f6106',
    // textDecoration: 'underline',
    color: '#025CCA',
    display: 'inline',
    fontWeight: '700',
  },
  nonHighlighted: {
    // fontWeight: 'normal',
    // backgroundColor: 'transparent',
    // color: 'black',
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    display: 'inline',
    fontWeight: '500',
  },
}
