import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  ALL_PRODUCTS,
  CREATE_INVOICE,
  LOGOUT,
  RECEIVE_ORDER,
} from 'config/routes/paths'
import usePharmacy from 'hooks/usePharmacy'
import { Button, ListItemIcon, useTheme } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Logo from 'assets/Logo'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListIcon from '@mui/icons-material/List'
import AddIcon from '@mui/icons-material/Add'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'
import ReportIcon from '@mui/icons-material/Assessment'
import EditIcon from '@mui/icons-material/Edit'
import { uniqueId } from 'lodash'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2'

const menuOptions = [
  {
    category: 'Productos',
    data: [
      {
        title: 'Recibir lotes',
        description: 'Recibir nueva mercaderia',
        icon: Inventory2OutlinedIcon,
        path: RECEIVE_ORDER,
      },
      {
        title: 'Agregar nuevo producto',
        description: 'Regitrar productos nuevos al inventario',
        icon: AddIcon,
        path: 'RECEIVE_ORDER',
      },
      {
        title: 'Productos',
        description: 'Lista de productos',
        module: 'Productos',
        icon: ListIcon,
        path: ALL_PRODUCTS,
      },
      {
        title: 'Editar productos',
        description: 'Editar codigo, nombre, stock, etc.',
        icon: EditIcon,
        path: 'RECEIVE_ORDER',
      },
    ],
  },
  {
    category: 'Ventas',
    data: [
      {
        title: 'Facturar',
        description: 'Realizar ventas',
        icon: PointOfSaleIcon,
        path: CREATE_INVOICE,
      },
      {
        title: 'Transacciones',
        description: 'Lista de todas las ventas',
        icon: ListIcon,
        path: CREATE_INVOICE,
      },
      {
        title: 'Reportes de ventas',
        description: 'Ventas por dia, por cliente y por usuario',
        icon: ReportIcon,
        path: CREATE_INVOICE,
      },
    ],
  },
]

const activeBgColor = 'rgba(235, 245, 255, 0.8)'
const activeBorder = '1px solid rgb(204, 230, 255)'

export default function NavigationMenu() {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const theme = useTheme()
  let location = useLocation()

  const open = Boolean(anchorEl)

  const handleMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const goTo = path => {
    navigate(path)
  }

  const activePath = location?.pathname

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <Logo />

      <Box
        sx={{
          ml: 2,
          cursor: 'pointer',
          padding: 1,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
            borderRadius: 1,
          },
        }}
        size="small"
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleMenu}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant="h6"
            color="text.primary"
            sx={{ minWidth: 100, mr: 0.5 }}
          >
            Productos
          </Typography>
        </Box>

        <Button
          variant="text"
          endIcon={<KeyboardArrowDownIcon color={theme.palette.text.primary} />}
          sx={{
            textTransform: 'capitalize',
            padding: 0,
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          Recibir productos
        </Button>
      </Box>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          '.MuiMenu-paper': {
            border: '1px solid rgb(232, 234, 238)',
            boxShadow: 'none',
            boxShadow: `rgba(9, 11, 11, 0.15) 0px 4px 16px`,
            overflow: 'clip',
          },
        }}
      >
        {menuOptions?.map((section, index) => {
          const isLast = index === menuOptions.length - 1

          return (
            <List
              key={section?.category}
              sx={{
                borderBottom: isLast
                  ? 'none'
                  : `1px solid ${theme.palette.divider}`,
                padding: 2,
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 'bold', mb: 1 }}
              >
                {section.category}
              </Typography>

              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gridGap: '8px',
                }}
              >
                {section?.data?.map((item, index2) => {
                  const Icon = item.icon

                  const isActive = item.path === activePath

                  const bgColor = isActive ? activeBgColor : 'transparent'

                  return (
                    <Box
                      key={uniqueId()}
                      sx={{
                        borderRadius: 1,
                        padding: '0 16px',
                        backgroundColor: bgColor,
                        border: isActive
                          ? activeBorder
                          : '1px solid transparent',
                        cursor: 'pointer',
                        maxWidth: '350px',
                        '&:hover': {
                          border: '1px solid rgb(232, 234, 238)',
                          backgroundColor: 'rgb(246, 247, 248)',
                        },
                      }}
                      onClick={() => {
                        goTo(item?.path)
                      }}
                    >
                      <ListItem sx={{ padding: 0 }}>
                        <ListItemIcon>
                          <Icon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <ListItemText
                          primary={item.title}
                          secondary={item.description}
                        />
                      </ListItem>
                    </Box>
                  )
                })}
              </Box>
            </List>
          )
        })}
      </Menu>
    </Box>
  )
}
