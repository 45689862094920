import React from 'react'
import { Box } from '@mui/material'

export default function Wrapper({
  children,
  bgColor = 'transparent',
  padding = 2,
}) {
  return (
    <Box
      sx={{
        width: '95%',
        // maxWidth: '1536px',
        maxWidth: '1400px',
        margin: '0 auto',
        padding: padding,
        backgroundColor: bgColor,
      }}
    >
      {children}
    </Box>
  )
}
