import React, { useEffect, useState } from 'react'
import { Box, Card, Typography, Button, Tabs, Tab, Badge } from '@mui/material'
import Container from 'components/Container'
import InvoiceResume from 'features/sales/components/InvoiceResume'
import InvoiceProducts from 'features/sales/components/InvoiceProducts'
import { useTheme } from '@mui/material/styles'
import { ScanInput } from 'components/ScanInput'
import useCreateInvoice from './useCreateInvoice'
import { isEmpty } from 'lodash'
import { Toaster } from 'sonner'
import { Helmet } from 'react-helmet'
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined'
import DosageCalculator from 'components/DosageCalculator'
import SalesLayout from 'features/sales/layout/SalesLayout'
import FlexContainer from 'components/FlexContainer'

//Icons
import ChildCareOutlinedIcon from '@mui/icons-material/ChildCareOutlined'
import InvoiceIcon from '@mui/icons-material/ReceiptOutlined'

export default function CreateInvoice() {
  const [currentInvoice, setCurrentInvoice] = useState('invoice_1')
  const invoice1 = useCreateInvoice({ ID: 'invoice_1' })
  const invoice2 = useCreateInvoice({ ID: 'invoice_2' })
  const invoice3 = useCreateInvoice({ ID: 'invoice_3' })

  const theme = useTheme()

  const allInvoices = {
    invoice_1: invoice1,
    invoice_2: invoice2,
    invoice_3: invoice3,
  }

  const invoices = [
    { label: 'Factura #1', value: 'invoice_1', isActive: false },
    { label: 'Factura #2', value: 'invoice_2', isActive: false },
    { label: 'Factura #3', value: 'invoice_3', isActive: false },
  ]

  const selectedInvoice = allInvoices[currentInvoice]

  const {
    products,
    addProductFromList,
    onBarcodeScanWithScanner,
    inputRef,
    invoiceResumeProps,
    invoiceProductsProps,
    calculatorModal,
  } = selectedInvoice

  const InvoicesLabels = {
    invoice_1: '#1',
    invoice_2: '#2',
    invoice_3: '#3',
  }

  useEffect(() => {
    inputRef?.current?.focus()
  }, [currentInvoice])

  return (
    <Container
      maxHeight="100vh"
      minHeight="500px"
      overflow="hidden"
      hideScrollbar
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Facturacion</title>
      </Helmet>
      <SalesLayout
        resume={
          <InvoiceResume
            currentInvoice={currentInvoice}
            {...invoiceResumeProps}
          />
        }
      >
        <FlexContainer alignItems="flex-start">
          <Typography
            variant="h5"
            sx={{
              fontWeight: '700',
              mb: 0,
              pb: 0,
              lineHeight: 1,
            }}
          >
            Facturación
          </Typography>
          <Card
            variant="outlined"
            sx={{ background: 'transparent', border: 'none' }}
          >
            <FlexContainer>
              <Typography variant="body2" sx={{ mr: 1, whiteSpace: 'nowrap' }}>
                Calcular dosis:
              </Typography>
              <Button
                fullWidth
                variant="outlined"
                startIcon={<ChildCareOutlinedIcon />}
                onClick={calculatorModal.open}
                sx={{ mr: 1, textTransform: 'none' }}
              >
                Pediatrica
              </Button>
            </FlexContainer>
          </Card>
        </FlexContainer>

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={currentInvoice}
            onChange={(_, value) => {
              setCurrentInvoice(value)
            }}
            sx={{
              '& .MuiTab-root': {
                margin: 0,
                minHeight: '40px !important',
                textTransform: 'none !important',
              },
            }}
          >
            {invoices?.map(invoice => {
              const selected = invoice?.value === currentInvoice

              const invoiceData = allInvoices[invoice?.value]

              const { productsCount = 0 } = invoiceData?.invoiceResumeProps

              return (
                <Tab
                  key={invoice.value}
                  size="small"
                  iconPosition="start"
                  icon={<InvoiceIcon fontSize="small" />}
                  label={
                    productsCount ? (
                      <Badge
                        badgeContent={productsCount}
                        color="error"
                        invisible={selected}
                      >
                        <Typography variant="body2" sx={{ mr: 3 }}>
                          {invoice?.label}
                        </Typography>
                      </Badge>
                    ) : (
                      invoice?.label
                    )
                  }
                  value={invoice?.value}
                />
              )
            })}
          </Tabs>
        </Box>
        <Card
          elevation={0}
          sx={{
            mt: 2,
            backgroundColor: 'transparent',
            width: '100%',
            height: '100%',
          }}
        >
          <Box>
            <ScanInput
              action={({ productCode, onFailure }) => {
                onBarcodeScanWithScanner({ productCode, onFailure })
              }}
              onSelect={product => {
                const result = addProductFromList(product)

                return result
              }}
              inputRef={inputRef}
            />
          </Box>
          {!isEmpty(products) ? (
            <Typography
              variant="subtitle1"
              sx={{ mt: 2, mb: 1 }}
              color="text.secondary"
            >
              {`Productos de la Factura ${InvoicesLabels[currentInvoice]}`}
            </Typography>
          ) : null}

          {!isEmpty(products) ? (
            <InvoiceProducts {...invoiceProductsProps} />
          ) : (
            <Box
              sx={{
                margin: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  borderRadius: '100px',
                  width: '100px',
                  height: '100px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: theme.palette.primary2['100'],
                  mt: 10,
                  mb: 2,
                }}
              >
                <Inventory2OutlinedIcon fontSize="large" color="primary" />
              </Box>

              <Typography
                variant="subtitle1"
                color="text.primary"
                sx={{ fontWeight: '600' }}
              >
                Aún no tienes productos en esta factura
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Los productos que añadas a la factura aparecerán aquí
              </Typography>
            </Box>
          )}
        </Card>
      </SalesLayout>
      {calculatorModal.visible ? (
        <DosageCalculator onClose={calculatorModal.close} />
      ) : null}

      <Toaster richColors position="top-right" />
    </Container>
  )
}
