import { useEffect } from 'react'

export function useKeyboardEvents({
  keyCode,
  action = () => {},
  ctrlKey = true,
  shiftKey = true,
  altKey = true,
  ref = null,
}) {
  useEffect(() => {
    function onKeyDown(event) {
      const ctrlCondition = ctrlKey === event.metaKey || event.ctrlKey
      const shiftCondition = event.shiftKey === shiftKey
      const altKeyCondition = event.altKey === altKey

      let condition =
        event.keyCode === keyCode &&
        ctrlCondition &&
        shiftCondition &&
        altKeyCondition

      if (condition) {
        event.preventDefault()

        action()
      }
    }

    window.addEventListener('keydown', onKeyDown)

    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [action, keyCode, shiftKey, ref])
}
