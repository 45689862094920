import { db } from 'config/firebase'
import { getDoc, doc } from 'firebase/firestore'

export const getProductRef = product_doc => {
  const product_ref = doc(db, 'products', product_doc)

  //   const product_ref = getDoc(docRef)

  return product_ref
}
