import React, { useCallback, useMemo, useState } from 'react'
import {
  Chip,
  useTheme,
  Checkbox,
  Badge,
  Box,
  TextField,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  InputAdornment,
} from '@mui/material'
import useModal from 'hooks/useModal'
import FlexContainer from 'components/FlexContainer'
import { debounce, isEmpty, uniqueId } from 'lodash'
import { ChevronLeft, Search } from '@mui/icons-material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

export default function ChipFilterAlgolia({
  options,
  title = '',
  onChange = () => {},
  label = '',
  searchForItems,
  hideSearchbar = false,
  size = 'small',
  borderRadius = 1,
  padding = '24px 4px',
}) {
  const theme = useTheme()
  const [searchTerm, setSearchTerm] = useState('')
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const closeModal = () => {
    updateSearchTerm('')

    handleClose()
  }

  const handlePress = item => {
    onChange(item?.value)
    updateSearchTerm('')
    handleClose()
  }

  const sendQuery = useCallback(
    searchTerm => {
      searchForItems(searchTerm)
    },
    [searchForItems]
  )

  const debouncedSendQuery = useMemo(() => {
    return debounce(sendQuery, 500)
  }, [sendQuery])

  const selectedItems = useMemo(() => {
    if (isEmpty(options)) return 0

    const items = options?.filter(option => {
      return option.isRefined === true
    })

    return items
  }, [options])

  const updateSearchTerm = _searchTerm => {
    setSearchTerm(_searchTerm)
    debouncedSendQuery(_searchTerm)
  }

  return (
    <Box>
      <Badge
        badgeContent={selectedItems?.length}
        color="primary"
        invisible={selectedItems?.length < 2}
      >
        <Chip
          size={size}
          variant="outlined"
          sx={{
            backgroundColor: 'transparent',
            borderRadius: borderRadius,
            padding,
          }}
          onClick={handleClick}
          deleteIcon={<KeyboardArrowDownIcon />}
          onDelete={handleClick}
          label={
            !isEmpty(selectedItems)
              ? typeof selectedItems[0]?.label === 'string'
                ? `${label}: ${selectedItems[0]?.label}`
                : selectedItems[0]?.label
              : label
          }
        />
      </Badge>

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          '.MuiMenu-paper': {
            width: '350px',
          },
        }}
      >
        {!hideSearchbar ? (
          <Box sx={{ pl: 1, pr: 1, mb: 1 }}>
            <TextField
              hiddenLabel
              fullWidth
              placeholder={`Buscar ${label}`}
              onChange={event => {
                updateSearchTerm(event.target.value)
              }}
              value={searchTerm}
              variant="filled"
              size="small"
              sx={{
                '.MuiFilledInput-root': {
                  borderRadius: '8px',
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        ) : null}

        <Box sx={{ maxHeight: '350px' }}>
          {options?.map(item => {
            const key = uniqueId()

            return (
              <React.Fragment key={key}>
                <ListItemButton
                  onClick={() => {
                    handlePress(item)
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={item?.isRefined}
                      disableRipple
                      inputProps={{ 'aria-labelledby': key }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      whiteSpace: 'nowrap',
                    }}
                    id={key}
                    primary={item?.label}
                  />

                  <Chip label={item.count} />
                </ListItemButton>
              </React.Fragment>
            )
          })}
        </Box>
      </Menu>
    </Box>
  )
}
