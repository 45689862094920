import { getDoc } from 'firebase/firestore'

export const getDataFromRef = async ref => {
  const response = await getDoc(ref)

  return {
    doc_ID: response?.id,
    ...response?.data(),
  }
}
