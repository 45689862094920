import * as React from 'react'
import ListSubheader from '@mui/material/ListSubheader'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import DraftsIcon from '@mui/icons-material/Drafts'
import SendIcon from '@mui/icons-material/Send'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import StarBorder from '@mui/icons-material/StarBorder'
import { Box } from '@mui/material'

export default function CollapseList({
  title,
  icon = null,
  description,
  initialValue = false,
  children,
}) {
  const [open, setOpen] = React.useState(initialValue)

  const Icon = icon

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <Box
      sx={theme => ({
        mb: 1,
        padding: 1,
        // background: theme.palette.neutral[100],
        borderRadius: 1,
        border: `1px solid ${theme.palette.divider}`,
      })}
    >
      <ListItemButton
        onClick={handleClick}
        sx={{
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&:active': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: '32px',
          }}
        >
          {icon ? <Icon fontSize="16px" color="primary" /> : null}
        </ListItemIcon>
        <ListItemText
          primary={title}
          //   sx={{
          //     '.MuiListItemText-primary': {
          //       fontWeight: '500',
          //     },
          //   }}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        sx={theme => ({
          mb: 3,

          //   mx: 5,
          px: 2,
          //   background: theme.palette.neutral[100],
          //   borderRadius: 1,
          //   border: `1px solid ${theme.palette.divider}`,
        })}
      >
        {children}
      </Collapse>
    </Box>
  )
}
