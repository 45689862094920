import { Add } from '@mui/icons-material'
import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material'
import FormCustomers from 'features/customers/views/FormCustomers'
import useModal from 'hooks/useModal'
import { isEmpty } from 'lodash'
import React from 'react'
import randomKey from 'utils/randomKey'
import { uniqueId } from 'lodash'

export default function BaseSelector({
  data,
  getData = () => {},
  size = 'small',
  variant = 'filled',
  value = {},
  setValue = () => {},
  formModal = null,
  ctaButton = {
    text: 'Agregar nuevo cliente',
    action: () => {},
  },
  inputLabel = 'Cliente',
}) {
  const customerForm = useModal()

  const key = randomKey()

  const id = `${key}_selector`

  return (
    <Box>
      <Autocomplete
        disablePortal
        id={id}
        options={!isEmpty(data) ? data : []}
        fullWidth
        getOptionLabel={option => option.label}
        getOptionKey={option => uniqueId(option?.value)}
        value={!isEmpty(value) ? value : { label: '' }}
        onChange={(_, newValue) => {
          setValue(newValue)
        }}
        autoHighlight
        noOptionsText={
          <Box>
            <Typography
              variant="body2"
              color="text.secondary"
              align="center"
              sx={{ mb: 2 }}
            >
              No se encontraron resultados
            </Typography>

            <Button
              fullWidth
              variant="outlined"
              size="small"
              startIcon={<Add />}
              onClick={formModal.open}
            >
              {ctaButton?.text}
            </Button>
          </Box>
        }
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            size={size}
            variant={variant}
            label={inputLabel}
          />
        )}
      />

      {customerForm.visible ? (
        <FormCustomers
          onClose={customerForm.close}
          onSuccess={customer => {
            setValue(customer)
            getData()
            customerForm.close()
          }}
        />
      ) : null}
    </Box>
  )
}
