import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  useTheme,
} from '@mui/material'
import React from 'react'
import MoreIcon from '@mui/icons-material/MoreHorizOutlined'
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined'
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined'
import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined'
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined'
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined'
import ProductDetail from '../ProductDetail'
import useModal from 'hooks/useModal'

export default function ProductOptions({
  data,
  archiveProduct,
  unarchiveProduct,
}) {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const productDetail = useModal()

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const options = [
    {
      label: 'Ver detalle',
      value: 'SEE_DETAIL',
      icon: OpenInNewOutlinedIcon,
      action: () => {
        productDetail.open({ data: { product_ID: data?.product_code } })
      },
    },
    // {
    //   label: 'Ver historial',
    //   value: 'HISTORY',
    //   icon: HistoryOutlinedIcon,
    //   action: () => {
    //     console.log({ data, action: 'HISTORY' })
    //   },
    // },

    // {
    //   divider: true,
    //   label: 'Agregar a la lista de compras',
    //   value: 'ADD_TO_CART',
    //   icon: AddShoppingCartOutlinedIcon,
    //   action: () => {
    //     console.log({ data, action: 'ADD_TO_CART' })
    //   },
    // },
    data.archived
      ? {
          color: 'success',
          label: 'Desarchivar',
          value: 'UNARCHIVE_PRODUCT',
          icon: UnarchiveOutlinedIcon,
          action: () => {
            // console.log({ data, action: 'ARCHIVE_PRODUCT' })
            unarchiveProduct(data)
          },
        }
      : {
          color: 'error',
          label: 'Archivar',
          value: 'ARCHIVE_PRODUCT',
          icon: ArchiveOutlinedIcon,
          action: () => {
            // console.log({ data, action: 'ARCHIVE_PRODUCT' })
            archiveProduct(data)
          },
        },
  ]

  return (
    <Box>
      <IconButton
        sx={{
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: 1,
        }}
        onClick={handleClick}
      >
        <MoreIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        // PaperProps={{
        //   style: {
        //     maxHeight: ITEM_HEIGHT * 4.5,
        //     width: '20ch',
        //   },
        // }}
      >
        {options?.map(option => {
          const Icon = option.icon

          return (
            <MenuItem
              divider={Boolean(option?.divider)}
              key={option.value}
              onClick={() => {
                option?.action()
                handleClose()
              }}
              color={option?.color ? option?.color : 'text.primary'}
            >
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={option?.label} />
            </MenuItem>
          )
        })}
      </Menu>

      {productDetail.visible ? (
        <ProductDetail
          product_ID={productDetail?.data?.product_ID}
          onClose={productDetail.close}
        />
      ) : null}
    </Box>
  )
}
