import React, { useState } from 'react'
import { useHits, useInstantSearch, usePagination } from 'react-instantsearch'
import { uniqueId } from 'lodash'
import { Box, Divider } from '@mui/material'
import useParsers from 'hooks/useParsers'
import ProductItemSearch from 'components/ProductItemSearch'
import { useKeyboardEvents } from 'hooks/useKeyboardEvents'

function Hit(event) {
  const {
    hit,
    parseAmount = () => {},
    isActive = false,
    onClick = () => {},
  } = event

  return (
    <ProductItemSearch
      isActive={isActive}
      parseAmount={parseAmount}
      product={hit}
      onClick={onClick}
    />
  )
}

const ArrowUp = 38
const ArrowDown = 40
const EnterKey = 13
const firstIndex = 0

const NUMBER_OF_VISIBLE_PRODUCTS = 5

export function PaginateHits({
  scrollTop = () => {},
  scrollBottom = () => {},
  onSelect = () => {},
}) {
  const { hits, sendEvent } = useHits({})

  const {
    currentRefinement,
    nbHits,
    nbPages,
    isFirstPage,
    isLastPage,
    refine,
  } = usePagination()
  const { status } = useInstantSearch()
  const { parseAmount } = useParsers()
  const [activeIndex, setActiveIndex] = useState(0)

  const prevIndex = () => {
    setActiveIndex(prevIndex => {
      const isFirst = prevIndex === firstIndex

      const newIndex = isFirst ? firstIndex : prevIndex - 1

      if (newIndex < NUMBER_OF_VISIBLE_PRODUCTS) {
        scrollTop()
      }

      return newIndex
    })
  }

  const nextIndex = () => {
    const lastIndex = hits?.length - 1

    setActiveIndex(prevIndex => {
      const isLast = prevIndex === lastIndex

      const newIndex = isLast ? lastIndex : prevIndex + 1

      if (newIndex >= NUMBER_OF_VISIBLE_PRODUCTS) {
        scrollBottom({ index: newIndex, isLast })
      }

      return newIndex
    })
  }

  const selectProduct = () => {
    const product = hits[activeIndex]

    onSelect(product)
  }

  const onClickItem = index => {
    const product = hits[index]

    onSelect(product)
  }

  useKeyboardEvents({
    keyCode: ArrowUp,
    action: prevIndex,
    ctrlKey: false,
    shiftKey: false,
    altKey: false,
  })

  useKeyboardEvents({
    keyCode: ArrowDown,
    action: nextIndex,
    ctrlKey: false,
    shiftKey: false,
    altKey: false,
  })

  useKeyboardEvents({
    keyCode: EnterKey,
    action: selectProduct,
    ctrlKey: false,
    shiftKey: false,
    altKey: false,
  })

  if (status === 'loading' || status === 'stalled') return <Box>Loading</Box>

  return (
    <Box>
      {hits?.map((hit, index) => {
        return (
          <React.Fragment key={uniqueId()}>
            <Hit
              isActive={index === activeIndex}
              hit={hit}
              parseAmount={parseAmount}
              onClick={() => onClickItem(index)}
            />
          </React.Fragment>
        )
      })}
    </Box>
  )
}
