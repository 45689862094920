import { Box } from '@mui/material'
import BaseSelector from 'components/BaseSelector'
import useLaboratorySelector from 'hooks/selectors/useLaboratorySelector'
import useModal from 'hooks/useModal'
import React from 'react'

export default function LaboratorySelector({
  size = 'small',
  variant = 'filled',
  value = {},
  setValue = () => {},
}) {
  const form = useModal()
  const { state, getData } = useLaboratorySelector()

  return (
    <Box>
      <BaseSelector
        size={size}
        variant={variant}
        data={state?.data}
        getData={getData}
        formModal={form}
        inputLabel="Laboratorio"
        ctaButton={{
          text: 'Agregar Nuevo Laboratorio',
          action: () => {},
        }}
        value={value}
        setValue={setValue}
      />
    </Box>
  )
}
