import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ThemeProvider } from '@mui/material'
import { theme } from 'const/theme'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { es } from 'date-fns/locale/es'
import { AuthContextProvider } from 'contexts/AuthContext'
import { PharmacyContextProvider } from 'contexts/pharmacyContext'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
      <AuthContextProvider>
        <PharmacyContextProvider>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </PharmacyContextProvider>
      </AuthContextProvider>
    </LocalizationProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
