import { createContext, useMemo, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { auth } from 'config/firebase'
import { isEmpty } from 'lodash'
import { onAuthStateChanged } from 'firebase/auth'

export const AuthContext = createContext()

export function AuthContextProvider({ children }) {
  const [userUID, setUserUID] = useState({})
  const [initializing, setInitializing] = useState(true)

  const logout = useCallback(() => {
    // window.localStorage.clear()
  }, [])

  function onAuthChanged(_user) {
    const uid = _user?.uid

    setUserUID(uid)

    if (initializing) {
      setInitializing(false)
    }
  }

  const isAuth = useMemo(() => {
    return Boolean(userUID)
  }, [userUID])

  const value = useMemo(() => {
    return { logout, isAuth, initializing }
  }, [logout, isAuth, initializing])

  useEffect(() => {
    const subscriber = onAuthStateChanged(auth, onAuthChanged)

    return subscriber // unsubscribe on unmount
  }, [])

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

AuthContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
}
