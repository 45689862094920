import { numberIsValid } from 'utils/numbers/numberIsValid'
import { isEmpty, floor, sumBy } from 'lodash'
import { differenceInDays, startOfMonth, subMonths } from 'date-fns'
import { formatDate } from 'utils/formatDate'
import { db } from 'config/firebase'
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore'

export const INITIAL_STATS = {
  range: {
    label: '',
    start: new Date(),
    end: new Date(),
  },
  count: {
    day: 0,
    week: 0,
    month: 0,
    total: 0,
  },
  revenue: {
    day: 0,
    week: 0,
    month: 0,
    total: 0,
  },
  soldOutIn: {
    status: '',
    days: 0,
  },
}

const ONE_WEEK = 7
const TWO_WEEKS = 14
const THREE_WEEKS = 21

const ONE_MONTH = 30
const A_LONG_MONTH = 31

const getRotationStatus = ({ soldOutIn }) => {
  if (soldOutIn <= ONE_WEEK) return 'ONE_WEEK'
  if (soldOutIn > ONE_WEEK && soldOutIn <= TWO_WEEKS) return 'TWO_WEEKS'
  if (soldOutIn > TWO_WEEKS && soldOutIn <= THREE_WEEKS) return 'THREE_WEEKS'
  if (soldOutIn > THREE_WEEKS && soldOutIn <= A_LONG_MONTH) return 'ONE_MONTH'

  return 'MORE_THAN_A_MONTH'
}

const parseValue = value => {
  const result = numberIsValid(value) ? floor(value, 2) : 0

  return result
}

export const getProductStatistics = async ({
  pharmacy_ID,
  product_ID,
  stock,
}) => {
  try {
    //Mes actual
    const currentMonth = startOfMonth(new Date())
    const currentDate = new Date()
    const currentMonthString = formatDate(currentMonth, 'yyyy-MM-dd')

    //Mes Pasado
    const lastMonth = startOfMonth(subMonths(currentMonth, 1))
    const lastMonthString = formatDate(lastMonth, 'yyyy-MM-dd')

    //Mes antepasado
    const beforeLastMonth = startOfMonth(subMonths(currentMonth, 2))
    const beforeLastMonthString = formatDate(beforeLastMonth, 'yyyy-MM-dd')

    //Dias transcurridos
    const daysElapsedUntilToday = differenceInDays(new Date(), beforeLastMonth)

    const q = query(
      collection(db, 'monthly_sales_by_product'),
      where('pharmacy_ID', '==', pharmacy_ID),
      where('product_ID', '==', product_ID),
      where('month_value', 'in', [
        beforeLastMonthString,
        lastMonthString,
        currentMonthString,
      ]),
      orderBy('month_value', 'desc'),
      limit(3)
    )

    const querySnapshot = await getDocs(q)

    const summaries = []

    querySnapshot.forEach(documentSnapshot => {
      if (!documentSnapshot?.exists) return

      const data = documentSnapshot.data()

      const result = {
        ...data,
        doc_ID: documentSnapshot.id,
      }

      summaries.push(result)
    })

    //Promedios
    let averagePerDay
    let averagePerWeek
    let averagePerMonth

    //Recuentos
    let rangeLabel
    let totalSalesCount

    //Revenue
    let revenuePerDay
    let revenuePerWeek
    let revenuePerMonth
    let totalRevenue

    //Stock
    let soldOutIn
    let status

    if (!isEmpty(summaries)) {
      const totalSales = sumBy(summaries, 'total_sales')

      //Promedios
      averagePerDay = totalSales / daysElapsedUntilToday
      averagePerWeek = averagePerDay * ONE_WEEK
      averagePerMonth = averagePerDay * ONE_MONTH

      //Recuentos
      totalSalesCount = totalSales
      rangeLabel = `${formatDate(beforeLastMonth, 'd MMM yy')} - ${formatDate(
        currentDate,
        'd MMM yy'
      )}`

      const sumRevenue = sumBy(summaries, 'total_revenue')

      //Revenue
      revenuePerDay = sumRevenue / daysElapsedUntilToday
      revenuePerWeek = revenuePerDay * ONE_WEEK
      revenuePerMonth = revenuePerDay * ONE_MONTH
      totalRevenue = sumRevenue

      //Stock
      soldOutIn = stock / averagePerDay
      status = getRotationStatus({ soldOutIn })
    }

    const result = {
      range: {
        label: rangeLabel,
        start: beforeLastMonth,
        end: currentDate,
      },
      count: {
        day: parseValue(averagePerDay),
        week: parseValue(averagePerWeek),
        month: parseValue(averagePerMonth),
        total: totalSalesCount,
      },
      revenue: {
        day: parseValue(revenuePerDay),
        week: parseValue(revenuePerWeek),
        month: parseValue(revenuePerMonth),
        total: parseValue(totalRevenue),
      },
      soldOutIn: {
        status: status,
        days: Math.ceil(soldOutIn),
      },
    }

    return !isEmpty(summaries) ? result : INITIAL_STATS
  } catch (err) {
    console.error(err)
    console.error('Error in: getProductStatistics')
  }
}
