import { isEmpty, minBy } from 'lodash'
import { db } from 'config/firebase'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { getDefaultBatch } from 'utils/getDefaultBatch'

export const getBatches = async ({ pharmacy_ID, product_ref }) => {
  const q = query(
    collection(db, 'batches'),
    where('pharmacy_ID', '==', pharmacy_ID),
    where('productRef', '==', product_ref),
    where('stock', '>=', 1)
  )

  const querySnapshot = await getDocs(q)

  const batches = []

  querySnapshot.forEach(documentSnapshot => {
    if (!documentSnapshot.exists) return
    const data = documentSnapshot.data()

    const _batch = {
      ...data,
      batch_ID: documentSnapshot.id,
    }
    batches.push(_batch)
  })

  const has_batches = !isEmpty(batches)

  const d_batch = has_batches ? minBy(batches, 'expiration_date') : {}

  const default_batch = getDefaultBatch(d_batch)

  return {
    batches,
    default_batch,
  }
}
