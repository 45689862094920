import { createTheme } from '@mui/material'

export const theme = createTheme({
  palette: {
    mode: 'light',
    common: {
      black: '#000',
      white: '#fff',
    },
    primary: {
      main: '#025CCA',
      light: '#679DDF',
      dark: '#024EAB',
      contrastText: '#fff',
    },
    secondary: {
      main: '#68B5FB',
      light: '#B4DBFD',
      dark: '#5391C9',
      contrastText: '#fff',
    },
    error: {
      main: '#EE4E4F',
      light: '#F59595',
      dark: '#BE3E3F',
      contrastText: '#fff',
    },
    warning: {
      main: '#FDA62B',
      light: '#FECA80',
      dark: '#CA8522',
      contrastText: '#fff',
    },
    info: {
      main: '#0288d1',
      light: '#03a9f4',
      dark: '#01579b',
      contrastText: '#fff',
    },
    success: {
      main: '#50D794',
      light: '#96E7BF',
      dark: '#40AC76',
      contrastText: '#fff',
    },
    grey: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#bdbdbd',
      500: '#9e9e9e',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      A100: '#f5f5f5',
      A200: '#eeeeee',
      A400: '#bdbdbd',
      A700: '#616161',
    },
    neutral: {
      white: '#FFFFFF',
      100: '#F5F5F5',
      200: '#E8E8E8',
      300: '#C6C7C8',
      400: '#A2A4A4',
      500: '#797B7C',
      600: '#636566',
      700: '#37383A',
      800: '#292C2D',
      black: '#202325',
    },
    primary2: {
      100: '#D9E7F7',
      200: '#9ABEEA',
      300: '#679DDF',
      400: '#357DD5',
      500: '#025CCA',
      600: '#0253B6',
      700: '#024EAB',
      800: '#013C83',
      900: '#012E65',
      dark: '#1E3043',
    },
    secondary2: {
      100: '#F0F8FF',
      200: '#DCEEFE',
      300: '#B4DBFD',
      400: '#86C4FC',
      500: '#68B5FB',
      600: '#5EA3E2',
      700: '#5391C9',
      800: '#4476A3',
      900: '#345A7E',
      dark: '#293742',
    },
    danger: {
      100: '#FDECEC',
      200: '#F8B8B9',
      300: '#F59595',
      400: '#F17172',
      500: '#EE4E4F',
      600: '#DD6565',
      700: '#BE3E3F',
      800: '#9B3333',
      900: '#772728',
      dark: '#3D292A',
    },
    warning2: {
      100: '#FFEDD5',
      200: '#FEDBAA',
      300: '#FECA80',
      400: '#FDB855',
      500: '#FDA62B',
      600: '#E49527',
      700: '#CA8522',
      800: '#A46C1C',
      900: '#7F5316',
      dark: '#4E422C',
    },
    success2: {
      100: '#DCF7EA',
      200: '#B9EFD4',
      300: '#96E7BF',
      400: '#73DFA9',
      500: '#50D794',
      600: '#48C185',
      700: '#40AC76',
      800: '#348C60',
      900: '#286B4A',
      dark: '#2F4B41',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.6)',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    background: {
      paper: '#fff',
      default: '#F5F5F5',
    },
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.04)',
      hoverOpacity: 0.04,
      selected: 'rgba(0, 0, 0, 0.08)',
      selectedOpacity: 0.08,
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
      disabledOpacity: 0.38,
      focus: 'rgba(0, 0, 0, 0.12)',
      focusOpacity: 0.12,
      activatedOpacity: 0.12,
    },
  },
  shape: {
    borderRadius: 12,
    cardBorderRadius: 16,
  },
  typography: {
    htmlFontSize: 16,
    fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
      fontWeight: 300,
      fontSize: '6rem',
      lineHeight: 1.167,
      letterSpacing: '-0.01562em',
    },
    h2: {
      fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
      fontWeight: 300,
      fontSize: '3.75rem',
      lineHeight: 1.2,
      letterSpacing: '-0.00833em',
    },
    h3: {
      fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '3rem',
      lineHeight: 1.167,
      letterSpacing: '0em',
    },
    h4: {
      fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '2.125rem',
      lineHeight: 1.235,
      letterSpacing: '0.00735em',
    },
    h5: {
      fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '1.5rem',
      lineHeight: 1.334,
      letterSpacing: '0em',
    },
    h6: {
      fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: '1.25rem',
      lineHeight: 1.6,
      letterSpacing: '0.0075em',
    },
    subtitle1: {
      fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.75,
      letterSpacing: '0.00938em',
    },
    subtitle2: {
      fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: 1.57,
      letterSpacing: '0.00714em',
    },
    body1: {
      fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
    },
    body2: {
      fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
    button: {
      fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: 1.75,
      letterSpacing: '0.02857em',
      textTransform: 'uppercase',
    },
    caption: {
      fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 1.66,
      letterSpacing: '0.03333em',
    },
    overline: {
      fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 2.66,
      letterSpacing: '0.08333em',
      textTransform: 'uppercase',
    },
    inherit: {
      fontFamily: 'inherit',
      fontWeight: 'inherit',
      fontSize: 'inherit',
      lineHeight: 'inherit',
      letterSpacing: 'inherit',
    },
  },
})

// const fontFamily = '"Inter", "Helvetica", "Arial", sans-serif'

// const lineHeight = 1.2

// const fontWeightLight = 300
// const fontWeightRegular = 400
// const fontWeightMedium = 500
// const fontWeightSemiBold = 600
// const fontWeightBold = 700

// const headingStyle = {
//   fontFamily: fontFamily,
//   fontWeight: fontWeightBold,
//   lineHeight: lineHeight,
// }

// const titleStyle = {
//   fontFamily: fontFamily,
//   fontWeight: fontWeightSemiBold,
//   lineHeight: lineHeight,
// }
// const subtitleStyle = {
//   fontFamily: fontFamily,
//   fontWeight: fontWeightMedium,
//   lineHeight: lineHeight,
// }

// const bodyStyle = {
//   fontFamily: fontFamily,
//   fontWeight: fontWeightRegular,
//   lineHeight: lineHeight,
// }
