import { isEmpty } from 'lodash'

export const getAffectedProducts = ({ data, property = 'product_ID' }) => {
  if (isEmpty(data)) return ''

  const result = data?.map(item => item[property])

  const uniqueValues = [...new Set(result)]

  return uniqueValues
}
