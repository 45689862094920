import {
  Alert,
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import { Controller } from 'react-hook-form'

const itemSpace = 0.5

export default function ChangePriceDialog({
  onClose = () => {},
  automaticPrice,
  setAutomaticPrice,
  newPrice,
  currentPrice,
  setNewPrice,
}) {
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      manual_price: newPrice,
    },
  })

  const onSubmit = data => {
    setNewPrice(Number(data?.manual_price))
    onClose()
  }

  const getFormProps = ({ name, rules = { required: true } }) => {
    return {
      name,
      control,
      rules,
    }
  }

  return (
    <Dialog open={true} onClose={onClose} maxWidth="xs" fullWidth={true}>
      <DialogTitle>Precio del producto</DialogTitle>

      <Divider />
      <DialogContent>
        <ToggleButtonGroup
          fullWidth
          size={'small'}
          color="primary"
          value={automaticPrice ? 'AUTO_PRICE' : 'MANUAL_PRICE'}
          exclusive
          onChange={(_, value) => {
            if (value) {
              setAutomaticPrice(value === 'AUTO_PRICE')
            }
          }}
        >
          <ToggleButton value="AUTO_PRICE">Precio automático</ToggleButton>
          <ToggleButton value="MANUAL_PRICE">Precio Manual</ToggleButton>
        </ToggleButtonGroup>

        {automaticPrice ? (
          <Box>
            <Alert severity="info" sx={{ mt: 1 }}>
              Cuando el precio se establece en automático, el precio del
              producto será igual al precio del lote mas caro.
            </Alert>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr',
              }}
            >
              <Card
                variant="outlined"
                sx={{
                  border: 'none',
                  borderRadius: 1,
                  padding: 1,
                }}
              >
                <Typography variant="caption">Precio a usar</Typography>
                <Typography variant="h6">{currentPrice}</Typography>
              </Card>
            </Box>
          </Box>
        ) : (
          <Box sx={{ mt: 2, mb: 3 }}>
            <Typography sx={{ mb: itemSpace }} variant="subtitle1">
              Especifique el precio fijo que tendra el producto
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                {...getFormProps({ name: 'manual_price' })}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    size="small"
                    variant="filled"
                    type="number"
                    label="Nuevo precio"
                    error={errors['manual_price']}
                    helperText={
                      errors['manual_price'] ? 'Ingrese el nuevo precio' : ''
                    }
                  />
                )}
              />

              <Button
                sx={{ mt: 2 }}
                variant="contained"
                fullWidth
                type="submit"
              >
                Guardar
              </Button>
            </form>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}
