import React from 'react'
import { useRefinementList } from 'react-instantsearch-core'
import ChipFilterAlgolia from 'components/ChipFilterAlgolia'

export default function LaboratoryFilter({ size = 'small', borderRadius = 1 }) {
  const { items, refine, searchForItems } = useRefinementList({
    attribute: 'laboratory',
    operator: 'or',
  })

  return (
    <ChipFilterAlgolia
      options={items}
      label="Laboratorio"
      title={'Filtrar por Laboratorio'}
      defaultIndex={0}
      onChange={laboratory => {
        refine(laboratory)
      }}
      borderRadius={borderRadius}
      size={size}
      searchForItems={searchForItems}
    />
  )
}
