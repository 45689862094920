import React from 'react'
import { Box, useTheme } from '@mui/material'

export default function FlexContainer({
  flexDirection = 'row',
  alignItems = 'center',
  justifyContent = 'space-between',
  children,
  padding = 0,
  mb = 0,
  mt = 0,
  ml = 0,
  mr = 0,
  bgColor = 'transparent',
  borderRadius = 0,
  sx = {},
}) {
  const theme = useTheme()
  const backgroundColor = bgColor || theme.palette.neutral['white']

  const styles = {
    display: 'flex',
    flexDirection,
    alignItems,
    justifyContent,
    padding,
    backgroundColor,
    borderRadius,
  }

  return (
    <Box
      {...styles}
      sx={{
        mb,
        mt,
        ml,
        mr,
        padding,
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}
