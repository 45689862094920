import { useEffect, useState } from 'react'
import usePharmacy from 'hooks/usePharmacy'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { db } from 'config/firebase'

export default function useLocationsSelector() {
  const { selectedPharmacy } = usePharmacy()

  const [state, setState] = useState({
    loading: true,
    error: false,
    data: [],
  })

  const getData = async () => {
    try {
      const pharmacy_ID = selectedPharmacy?.pharmacy_ID

      const q = query(
        collection(db, 'locations'),
        where('pharmacy_ID', '==', pharmacy_ID)
      )

      const querySnapshot = await getDocs(q)

      if (querySnapshot?.empty) {
        setState({
          error: false,
          loading: false,
          data: [],
        })
      } else {
        const _data = []

        querySnapshot.forEach(documentSnapshot => {
          const response = documentSnapshot.data()
          const _item = {
            docID: documentSnapshot.id,
            ...response,
            label: response?.location,
            value: response?.location,
          }

          _data.push(_item)
        })

        setState({
          error: false,
          loading: false,
          data: _data,
        })
      }
    } catch (error) {
      console.error('Error al obtener la lista de Locations')
      console.error(error)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return { state, getData }
}
