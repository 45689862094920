import { isEmpty, isDate } from 'lodash'

export const getDefaultBatch = batch => {
  if (isEmpty(batch)) return {}

  const expiration_date = Boolean(batch?.has_an_expiration_date)
    ? batch?.expiration_date?.toDate() || ''
    : ''

  const batchExpirationDate = isDate(expiration_date) ? expiration_date : ''

  const default_batch = {
    batch_code: batch?.batch_code || '',
    batch_ID: batch?.batch_ID || '',
    has_an_expiration_date: batch?.has_an_expiration_date || false,
    expiration_month: batch?.expiration_month || '',
    expiration_year: batch?.expiration_year || '',
    sale_price: batch?.sale_price || 0,
    expiration_date: batchExpirationDate || '',
    stock: batch?.stock || 0,
    batch_key: batch?.batch_key || '',
  }

  return default_batch
}
