import { Navigate, Outlet } from 'react-router-dom'
import { LOGIN } from 'config/routes/paths.js'
import useAuthContext from 'features/auth/hooks/useAuthContext'
import Layout from 'components/Layout'

export default function AdminRoute() {
  const { isAuth } = useAuthContext()

  if (!isAuth) return <Navigate to={LOGIN} />

  //Pintar el componente que haga match con la url a la que nos estamos intentando dirigir
  return (
    <Layout>
      <Outlet />
    </Layout>
  )
}
